import { useAuth0 } from "@auth0/auth0-react";

type NavAvatar = {
  showName?: boolean;
};

export default function NavAvatar({ showName = false }: NavAvatar) {
  const { user } = useAuth0();

  return (
    <>
      {user?.picture ? (
        <img
          alt=""
          className="h-8 w-8 rounded-full bg-lynch-700"
          referrerPolicy="no-referrer"
          src={user.picture}
        />
      ) : (
        <span className="inline-block h-8 w-8 overflow-hidden rounded-full bg-gray-100">
          <svg
            className="h-full w-full text-cello-300"
            fill="currentColor"
            viewBox="0 0 24 24"
          >
            <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
          </svg>
        </span>
      )}
      {showName && user?.name && (
        <>
          <span className="sr-only">Your profile</span>
          <span aria-hidden="true">{user.name}</span>
        </>
      )}
    </>
  );
}
