import { Button } from "@getwellen/valesco";
import { EyeIcon, LinkIcon } from "@heroicons/react/24/outline";
import { ContentLoader } from "components/content-loader/ContentLoader";
import PageHeader from "components/page-header/PageHeader";
import { Tabs } from "components/tabs/Tabs";
import { memo } from "react";
import { Link } from "react-router-dom";

import TransitionsConfigsTab from "./tabs/TransitionsConfigsTab";
import { TransitionsVideosTab } from "./tabs/TransitionsVideosTab";
import useTransitionsViewPage from "./useTransitionsViewPage";

const TransitionsViewPage = () => {
  const { cmsUrl, currentTab, isLoading, transition, transitionId } =
    useTransitionsViewPage();

  if (isLoading) return <ContentLoader />;

  const { slug, name } = transition;

  const renderCurrentTab = () => {
    switch (currentTab) {
      case "videos":
        return <TransitionsVideosTab />;
      case "configs":
        return <TransitionsConfigsTab />;
      default:
        return null;
    }
  };

  return (
    <>
      <PageHeader
        breadcrumbs={[
          { label: "Transitions", href: "/transitions" },
          { label: slug || "", href: `/transitions/${slug}` }
        ]}
        title={name}
      >
        <span className="sm:ml-3">
          <Link to={`/transitions/${transition.slug}/preview`}>
            <Button action="primary" variant="bold">
              <EyeIcon className="h-5 w-5 text-white mr-1.5" />
              Preview
            </Button>
          </Link>
        </span>
        <span className="sm:ml-3">
          <Button
            action="secondary"
            onClick={() => {
              window.open(
                cmsUrl +
                  "/admin/content-manager/collectionType/api::transition.transition/" +
                  transitionId,
                "_blank"
              );
            }}
            variant="subtle"
          >
            <LinkIcon
              aria-hidden="true"
              className="-ml-1 mr-2 h-5 w-5 text-cello-500"
            />
            View in Strapi
          </Button>
        </span>
      </PageHeader>
      <div>
        <Tabs
          tabs={[
            {
              name: "Videos",
              href: `/transitions/${slug}/videos`,
              current: currentTab === "videos"
            },
            {
              name: "Configs",
              href: `/transitions/${slug}/configs`,
              current: currentTab === "configs"
            }
          ]}
        />
        <div className="py-8 mb-8 border-b">{renderCurrentTab()}</div>
      </div>
    </>
  );
};

export default memo(TransitionsViewPage);
