import { Bars3Icon } from "@heroicons/react/24/outline";

import NavAvatar from "../avatar/NavAvatar";

type MobileAppBar = {
  setSidebarOpen: (open: boolean) => void;
};

export default function MobileNavBar({ setSidebarOpen }: MobileAppBar) {
  return (
    <div className="sticky top-0 z-40 flex items-center gap-x-6 bg-lynch-500 text-white p-4 shadow-sm sm:px-6 lg:hidden">
      <button
        className="-m-2.5 p-2.5 text-indigo-200 lg:hidden"
        onClick={() => setSidebarOpen(true)}
        type="button"
      >
        <span className="sr-only">Open sidebar</span>
        <Bars3Icon aria-hidden="true" className="h-6 w-6" />
      </button>
      <div className="flex-1 text-sm font-semibold leading-6 text-white">
        Dashboard
      </div>
      <span className="sr-only">Your profile</span>
      <NavAvatar />
    </div>
  );
}
