import { Logo, LogoVariant } from "@getwellen/valesco";
import { ArrowLeftOnRectangleIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { twMerge } from "tailwind-merge";

import { NavItem } from ".";
import NavAvatar from "./avatar/NavAvatar";

type NavSidebar = {
  onLogoutClick(): void;
  items: NavItem[];
};

export default function NavSidebar({ items = [], onLogoutClick }: NavSidebar) {
  return (
    <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-lynch-500 px-6">
      <Link className="flex h-16 shrink-0 items-center justify-between" to="/">
        <Logo className="h-12 w-8 text-white" variant={LogoVariant.Short} />
        <span className="font-accent text-white">Admin</span>
      </Link>
      <nav className="flex flex-1 flex-col">
        <ul className="flex flex-1 flex-col gap-y-7">
          <li>
            <ul className="-mx-2 space-y-1">
              {items
                .filter((item) => item.section == "main")
                .map((item) => (
                  <li key={item.name}>
                    <a
                      className={twMerge(
                        item.current
                          ? "bg-lynch-700 text-white"
                          : "text-white hover:text-white hover:bg-lynch-700",
                        "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                      )}
                      href={item.href}
                    >
                      <item.icon
                        aria-hidden="true"
                        className={twMerge(
                          item.current
                            ? "text-white"
                            : "text-white group-hover:text-white",
                          "h-6 w-6 shrink-0"
                        )}
                      />
                      {item.name}
                    </a>
                  </li>
                ))}
              <li>
                <div className="text-xs font-semibold leading-6 text-white mt-8">
                  Manage
                </div>
                <ul className="-mx-2 mt-2 space-y-1">
                  {items
                    .filter((item) => item.section == "secondary")
                    .map((item) => (
                      <li key={item.name}>
                        <a
                          className={twMerge(
                            item.current
                              ? "bg-lynch-700 text-white"
                              : "text-white hover:text-white hover:bg-lynch-700",
                            "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                          )}
                          href={item.href}
                        >
                          <item.icon
                            aria-hidden="true"
                            className={twMerge(
                              item.current
                                ? "text-white"
                                : "text-white group-hover:text-white",
                              "h-6 w-6 shrink-0"
                            )}
                          />
                          {item.name}
                        </a>
                      </li>
                    ))}
                </ul>
              </li>
              <li>
                <div className="text-xs font-semibold leading-6 text-white mt-8">
                  Account
                </div>
                <ul className="-mx-2 mt-2 space-y-1">
                  <li>
                    <button
                      className="w-full text-white hover:text-white hover:bg-lynch-700  group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                      onClick={onLogoutClick}
                    >
                      <ArrowLeftOnRectangleIcon className="h-6 w-6 shrink-0 text-lynch-200 group-hover:text-white" />
                      Logout
                    </button>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li className="-mx-6 mt-auto">
            <div className="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-white hover:bg-lynch-700">
              <NavAvatar showName={true} />
            </div>
          </li>
        </ul>
      </nav>
    </div>
  );
}
