import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  ISO8601DateTime: any;
  JSON: any;
};

export type Activity = {
  __typename?: 'Activity';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  slug: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
};

export type Assessment = {
  __typename?: 'Assessment';
  assessmentType: Scalars['String'];
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  slug: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
};

/** Attributes for auth providers */
export type AuthProviderAttributes = {
  provider: Scalars['String'];
  uid: Scalars['String'];
};

export type Cart = {
  __typename?: 'Cart';
  currency: Scalars['String'];
  discountAmount: Scalars['Float'];
  discountAmountDisplay: Scalars['String'];
  discountCode?: Maybe<Scalars['String']>;
  items: Array<CartItem>;
  paymentTerms?: Maybe<Scalars['String']>;
  subtotal: Scalars['Float'];
  subtotalDisplay: Scalars['String'];
  total: Scalars['Float'];
  totalDisplay: Scalars['String'];
};

export type CartItem = {
  __typename?: 'CartItem';
  amount: Scalars['Float'];
  amountDisplay: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  itemType: CartItemType;
};

export enum CartItemType {
  Discount = 'DISCOUNT',
  FreeTrial = 'FREE_TRIAL',
  Price = 'PRICE'
}

/** Autogenerated return type of ChangePasswordAdmin. */
export type ChangePasswordAdminPayload = {
  __typename?: 'ChangePasswordAdminPayload';
  message: Scalars['String'];
};

/** Autogenerated return type of ChangePassword. */
export type ChangePasswordPayload = {
  __typename?: 'ChangePasswordPayload';
  message: Scalars['String'];
};

export type CustomerioUser = {
  __typename?: 'CustomerioUser';
  /** Customerio id for the user */
  cioId: Scalars['String'];
  /** Email address of the user */
  email: Scalars['String'];
};

export enum Day {
  Friday = 'Friday',
  Monday = 'Monday',
  Saturday = 'Saturday',
  Sunday = 'Sunday',
  Thursday = 'Thursday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday'
}

export type Exercise = {
  __typename?: 'Exercise';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  slug: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
};

export type FeatureFlag = {
  __typename?: 'FeatureFlag';
  /** Whether or not the feature is enabled for this user */
  enabled: Scalars['Boolean'];
  /** The feature name */
  feature: Scalars['String'];
};

/** Autogenerated return type of GeolocateAddress. */
export type GeolocateAddressPayload = {
  __typename?: 'GeolocateAddressPayload';
  /** Latitude for address */
  lat?: Maybe<Scalars['Float']>;
  /** Longitude for address */
  lng?: Maybe<Scalars['Float']>;
};

/** Autogenerated return type of ImportExercise. */
export type ImportExercisePayload = {
  __typename?: 'ImportExercisePayload';
  message: Scalars['String'];
};

/** Autogenerated return type of ImportExercises. */
export type ImportExercisesPayload = {
  __typename?: 'ImportExercisesPayload';
  message: Scalars['String'];
};

export type ImportStatus = {
  __typename?: 'ImportStatus';
  status: Scalars['String'];
};

/** Autogenerated return type of ImportWorkout. */
export type ImportWorkoutPayload = {
  __typename?: 'ImportWorkoutPayload';
  message: Scalars['String'];
};

/** Autogenerated return type of ImportWorkouts. */
export type ImportWorkoutsPayload = {
  __typename?: 'ImportWorkoutsPayload';
  message: Scalars['String'];
};

export enum Interval {
  Annually = 'ANNUALLY',
  Monthly = 'MONTHLY',
  Quarterly = 'QUARTERLY'
}

export type Lead = {
  __typename?: 'Lead';
  createdAt: Scalars['ISO8601DateTime'];
  email: Scalars['String'];
  id: Scalars['ID'];
  source: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
  utms?: Maybe<Scalars['JSON']>;
};

/** Location input type */
export type LocationAttributes = {
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  ipAddress?: InputMaybe<Scalars['String']>;
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
  postal?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
};

export type MusicPreference = {
  __typename?: 'MusicPreference';
  mix?: Maybe<Scalars['String']>;
  station?: Maybe<Scalars['String']>;
  volume?: Maybe<Scalars['Int']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptProviderDisclaimer: Provider;
  /** Accept a disclaimer */
  acceptUserDisclaimer: User;
  /** Cancel a subscription */
  cancelSubscription: Subscription;
  changePassword: ChangePasswordPayload;
  changePasswordAdmin: ChangePasswordAdminPayload;
  /** Complete a user onboarding */
  completeUserOnboarding: User;
  completeWorkoutProgramDayTask: WorkoutProgramDayTask;
  createLead: Lead;
  createPatientWorkout: PatientWorkout;
  createPersonalizationSwap: UserPersonalizationSwap;
  /** Create a provider user */
  createProviderUser: User;
  createShareableWorkout: Shareable;
  /** Create a subscription for the current user using the subscription attributes input type */
  createSubscription: Subscription;
  /** Create a user using the user attributes input type */
  createUser: User;
  createUserAssessmentResult: UserAssessmentResult;
  /** Create a user profile using the user profile attributes input type */
  createUserProfile: UserProfile;
  /** Create well tip result */
  createUserWellTipResult: UserWellTipResult;
  /** Create a user with a profile */
  createUserWithProfile: User;
  createWorkoutProgram: WorkoutProgram;
  createWorkoutRating: WorkoutRating;
  /** Geolocate a zip code */
  geolocateAddress?: Maybe<GeolocateAddressPayload>;
  importExercise: ImportExercisePayload;
  importExercises: ImportExercisesPayload;
  importWorkout: ImportWorkoutPayload;
  importWorkouts: ImportWorkoutsPayload;
  /** Login a provider user */
  loginProviderUser: User;
  /** Base mutation type that defines all mutations */
  loginUser: User;
  personalizeWorkoutProgram: WorkoutProgram;
  personalizeWorkoutProgramAdmin: WorkoutProgram;
  removePersonalizationSwap: UserPersonalizationSwap;
  /** Request a password reset, which will trigger an email to the customer if they have an account */
  requestPasswordReset: RequestPasswordResetPayload;
  resumeSubscription: Subscription;
  /** Show welcome message to user */
  seenWelcomeMessage: User;
  startAssessment: Assessment;
  startOnboarding?: Maybe<StartOnboardingPayload>;
  /** Start a subscription */
  startSubscription: Subscription;
  /** Start a subscription trial */
  startSubscriptionTrial: Subscription;
  startWorkout: Workout;
  startWorkoutProgramDay: WorkoutProgramDay;
  startWorkoutProgramDayTask?: Maybe<WorkoutProgramDayTask>;
  /** Submit a waitlist interest for a lead */
  submitWaitlistInterestForLead: WaitlistInterest;
  subscribeToNotification: UserNotification;
  subscribeToNotificationAdmin: UserNotification;
  /** Unlock a workout program day */
  unlockWorkoutProgramDayAdmin: WorkoutProgramDay;
  unsubscribeFromNotification: UserNotification;
  unsubscribeFromNotificationAdmin: UserNotification;
  /** Update a subscriptions payment method with a setup intent */
  updateSubscriptionPaymentMethod: Subscription;
  updateSubscriptionPlan: Subscription;
  /** Update a user given user attributes */
  updateUser: User;
  /** Update a user given user attributes */
  updateUserAdmin: User;
  /** Update an existing user music preference */
  updateUserMusicPreference: User;
  /** Update a user phone number */
  updateUserPhone: User;
  /** Update a user phone number */
  updateUserPhoneAdmin: User;
  /** Update a user profile given user profile attributes */
  updateUserProfile: UserProfile;
  /** Update a user profile given user profile attributes */
  updateUserProfileAdmin: UserProfile;
  /** Validate a password reset token */
  validatePasswordResetToken: ValidatePasswordResetTokenPayload;
  validatePromoCode: ValidatePromoCodePayload;
};


export type MutationAcceptProviderDisclaimerArgs = {
  providerId: Scalars['ID'];
};


export type MutationCancelSubscriptionArgs = {
  id: Scalars['ID'];
};


export type MutationChangePasswordArgs = {
  password: Scalars['String'];
  token: Scalars['String'];
};


export type MutationChangePasswordAdminArgs = {
  password: Scalars['String'];
  userId: Scalars['ID'];
};


export type MutationCompleteWorkoutProgramDayTaskArgs = {
  id: Scalars['ID'];
  manual?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateLeadArgs = {
  anonymousId?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  event?: InputMaybe<Scalars['String']>;
  source: Scalars['String'];
};


export type MutationCreatePatientWorkoutArgs = {
  attributes: PatientWorkoutAttributes;
  providerId: Scalars['ID'];
};


export type MutationCreatePersonalizationSwapArgs = {
  attributes: UserPersonalizationSwapAttributes;
};


export type MutationCreateProviderUserArgs = {
  attributes: UserAttributes;
  locationAttributes?: InputMaybe<LocationAttributes>;
};


export type MutationCreateShareableWorkoutArgs = {
  origin?: InputMaybe<Scalars['String']>;
  slug: Scalars['String'];
};


export type MutationCreateSubscriptionArgs = {
  attributes: SubscriptionAttributes;
  locationAttributes?: InputMaybe<LocationAttributes>;
};


export type MutationCreateUserArgs = {
  attributes: UserAttributes;
};


export type MutationCreateUserAssessmentResultArgs = {
  attributes: UserAssessmentResultAttributes;
};


export type MutationCreateUserProfileArgs = {
  attributes: UserProfileAttributes;
};


export type MutationCreateUserWellTipResultArgs = {
  attributes: UserWellTipResultAttributes;
};


export type MutationCreateUserWithProfileArgs = {
  locationAttributes?: InputMaybe<LocationAttributes>;
  profileAttributes: UserProfileAttributes;
  subscriptionAttributes?: InputMaybe<SubscriptionAttributes>;
  userAttributes: UserAttributes;
};


export type MutationCreateWorkoutRatingArgs = {
  attributes: WorkoutRatingAttributes;
};


export type MutationGeolocateAddressArgs = {
  address: Scalars['String'];
};


export type MutationImportExerciseArgs = {
  slug: Scalars['String'];
};


export type MutationImportWorkoutArgs = {
  slug: Scalars['String'];
};


export type MutationLoginProviderUserArgs = {
  locationAttributes?: InputMaybe<LocationAttributes>;
};


export type MutationLoginUserArgs = {
  location?: InputMaybe<LocationAttributes>;
};


export type MutationPersonalizeWorkoutProgramAdminArgs = {
  userId: Scalars['ID'];
};


export type MutationRemovePersonalizationSwapArgs = {
  id: Scalars['ID'];
};


export type MutationRequestPasswordResetArgs = {
  email: Scalars['String'];
  returnUrl: Scalars['String'];
};


export type MutationResumeSubscriptionArgs = {
  subscriptionId: Scalars['ID'];
};


export type MutationStartAssessmentArgs = {
  slug: Scalars['String'];
};


export type MutationStartOnboardingArgs = {
  anonymousId: Scalars['String'];
  email?: InputMaybe<Scalars['String']>;
  healthHistory?: InputMaybe<Array<ProfileItemAttributes>>;
};


export type MutationStartSubscriptionArgs = {
  attributes: SubscriptionAttributes;
};


export type MutationStartSubscriptionTrialArgs = {
  attributes: SubscriptionAttributes;
  unlockProgram?: InputMaybe<Scalars['Boolean']>;
};


export type MutationStartWorkoutArgs = {
  slug: Scalars['String'];
};


export type MutationStartWorkoutProgramDayArgs = {
  id: Scalars['ID'];
};


export type MutationStartWorkoutProgramDayTaskArgs = {
  id: Scalars['ID'];
};


export type MutationSubmitWaitlistInterestForLeadArgs = {
  leadId: Scalars['ID'];
  metadata?: InputMaybe<Scalars['JSON']>;
  waitlistType: Scalars['String'];
};


export type MutationSubscribeToNotificationArgs = {
  metadata: UserNotificationMetadataAttributes;
  notificationMethod: UserNotificationMethod;
  notificationType: NotificationType;
};


export type MutationSubscribeToNotificationAdminArgs = {
  metadata: UserNotificationMetadataAttributes;
  notificationMethod: UserNotificationMethod;
  notificationType: NotificationType;
  userId: Scalars['ID'];
};


export type MutationUnlockWorkoutProgramDayAdminArgs = {
  id: Scalars['ID'];
};


export type MutationUnsubscribeFromNotificationArgs = {
  notificationType: NotificationType;
};


export type MutationUnsubscribeFromNotificationAdminArgs = {
  notificationType: NotificationType;
  userId: Scalars['ID'];
};


export type MutationUpdateSubscriptionPaymentMethodArgs = {
  stripeSetupIntentId: Scalars['String'];
  subscriptionId: Scalars['ID'];
};


export type MutationUpdateSubscriptionPlanArgs = {
  stripePriceId: Scalars['String'];
  subscriptionId: Scalars['ID'];
};


export type MutationUpdateUserArgs = {
  attributes: UserUpdateAttributes;
};


export type MutationUpdateUserAdminArgs = {
  attributes: UserUpdateAttributes;
  userId: Scalars['ID'];
};


export type MutationUpdateUserMusicPreferenceArgs = {
  mix?: InputMaybe<Scalars['String']>;
  station?: InputMaybe<Scalars['String']>;
  volume?: InputMaybe<Scalars['Float']>;
};


export type MutationUpdateUserPhoneArgs = {
  phone: Scalars['String'];
};


export type MutationUpdateUserPhoneAdminArgs = {
  phone: Scalars['String'];
  userId: Scalars['ID'];
};


export type MutationUpdateUserProfileArgs = {
  attributes: UserProfileAttributes;
};


export type MutationUpdateUserProfileAdminArgs = {
  attributes: UserProfileAttributes;
  userId: Scalars['ID'];
};


export type MutationValidatePasswordResetTokenArgs = {
  token: Scalars['String'];
};


export type MutationValidatePromoCodeArgs = {
  code: Scalars['String'];
  countryCode?: InputMaybe<Scalars['String']>;
  stripePriceId?: InputMaybe<Scalars['String']>;
};

export enum NotificationType {
  WorkoutReminder = 'WORKOUT_REMINDER'
}

export type Patient = {
  __typename?: 'Patient';
  /** When the provider was created */
  createdAt: Scalars['ISO8601DateTime'];
  /** First name of the patient */
  email: Scalars['String'];
  /** First name of the patient */
  firstName: Scalars['String'];
  /** The ID of the patient */
  id: Scalars['ID'];
  /** Last name of the patient */
  lastName: Scalars['String'];
  patientWorkouts: Array<PatientWorkout>;
  provider: Provider;
  /** Status of the patient */
  status: PatientStatus;
  /** Last time the provider was updated */
  updatedAt: Scalars['ISO8601DateTime'];
  user?: Maybe<User>;
};

/** Attributes for creating a patient */
export type PatientAttributes = {
  /** The email of the patient */
  email: Scalars['String'];
  /** The first name of the patient */
  firstName: Scalars['String'];
  /** The last name of the patient */
  lastName: Scalars['String'];
};

export type PatientResults = {
  __typename?: 'PatientResults';
  hasMore: Scalars['Boolean'];
  results: Array<Patient>;
  totalCount: Scalars['Int'];
};

export type PatientSearchFilters = {
  provider?: InputMaybe<Scalars['String']>;
};

export enum PatientStatus {
  Onboarded = 'ONBOARDED',
  Onboarding = 'ONBOARDING',
  Prospecting = 'PROSPECTING',
  Subscribed = 'SUBSCRIBED',
  Trialing = 'TRIALING',
  Unsubscribed = 'UNSUBSCRIBED'
}

export type PatientWorkout = {
  __typename?: 'PatientWorkout';
  /** When the provider was created */
  createdAt: Scalars['ISO8601DateTime'];
  /** The ID of the patient */
  id: Scalars['ID'];
  patient: Patient;
  /** When the workout was sent to the patient */
  sentOn?: Maybe<Scalars['ISO8601DateTime']>;
  /** The slug of the workout */
  slug?: Maybe<Scalars['String']>;
  /** The status of the workout */
  status: Scalars['String'];
  /** Last time the provider was updated */
  updatedAt: Scalars['ISO8601DateTime'];
  /** This patients workout */
  workout?: Maybe<Workout>;
  /** This patients workout template */
  workoutTemplate: WorkoutTemplate;
};

/** Attributes for creating a workout template */
export type PatientWorkoutAttributes = {
  /** The patient to create the workout for */
  patient: PatientAttributes;
  /** The workout template to create the workout from */
  workoutTemplate: WorkoutTemplateAttributes;
};

export enum PlanType {
  Premium = 'PREMIUM',
  Starter = 'STARTER'
}

export type Profile = {
  __typename?: 'Profile';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  items?: Maybe<Array<ProfileItem>>;
  profileType?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type ProfileItem = {
  __typename?: 'ProfileItem';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  key: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
  value: Scalars['String'];
};

/** Attributes for creating profile items */
export type ProfileItemAttributes = {
  key: Scalars['String'];
  value: Scalars['String'];
};

export type Provider = {
  __typename?: 'Provider';
  /** When the provider was created */
  createdAt: Scalars['ISO8601DateTime'];
  /** Whether the provider has accepted the terms of service */
  hasAcceptedTerms: Scalars['Boolean'];
  /** The ID of the provider */
  id: Scalars['ID'];
  /** Name of the provider */
  name: Scalars['String'];
  /** Number of patients associated with the provider */
  patientCount?: Maybe<Scalars['Int']>;
  /** Number of programs sent by the provider */
  programsSent?: Maybe<Scalars['Int']>;
  /** URL-friendly slug of the provider */
  slug: Scalars['String'];
  /** Last time the provider was updated */
  updatedAt: Scalars['ISO8601DateTime'];
};

export type ProviderResults = {
  __typename?: 'ProviderResults';
  hasMore: Scalars['Boolean'];
  results: Array<Provider>;
  totalCount: Scalars['Int'];
};

/** Base query type that defines all queries */
export type Query = {
  __typename?: 'Query';
  getCurrentProviderUser?: Maybe<Provider>;
  /** Get the currently authenticated user */
  getCurrentUser?: Maybe<User>;
  getCurrentUserAssessmentResultById: UserAssessmentResult;
  getCurrentUserAssessmentResultBySlugAndTaskId: UserAssessmentResult;
  /** Get the current user assessments history, ordered by created at */
  getCurrentUserAssessmentResultHistory?: Maybe<Array<UserAssessmentResult>>;
  /** Get the current user assessments overview by assessment type */
  getCurrentUserAssessmentResultOverview?: Maybe<Array<UserAssessmentResult>>;
  getCurrentUserCart: Cart;
  getCurrentUserCompletedWorkoutPrograms: Array<WorkoutProgram>;
  /** Get the current users notifications */
  getCurrentUserNotifications: Array<UserNotification>;
  /** Get the current users payment history */
  getCurrentUserPaymentHistory?: Maybe<StripeResults>;
  /** Get the current users swaps */
  getCurrentUserPersonalizationSwaps?: Maybe<Array<UserPersonalizationSwap>>;
  /** Get the currently authenticated users' profile */
  getCurrentUserProfile: UserProfile;
  /** Get a current user's recommended plan based on health history */
  getCurrentUserRecommendedPlans?: Maybe<RecommendedPlan>;
  getCurrentUserStripeSetupIntent: StripeSetupIntent;
  /** Get the currently authneticated users' subscription */
  getCurrentUserSubscription?: Maybe<Subscription>;
  /** Get the currently authenticated users' subscription billing period end */
  getCurrentUserSubscriptionBillingPeriodEnd?: Maybe<Scalars['ISO8601DateTime']>;
  getCurrentUserSubscriptionSummary?: Maybe<SubscriptionSummary>;
  /** Get the current user's workout program */
  getCurrentUserWorkoutProgram?: Maybe<WorkoutProgram>;
  /** Get the current user's workout program day by key */
  getCurrentUserWorkoutProgramDayByKey: WorkoutProgramDay;
  /** Get a days task by its key for the current users currently active workout program */
  getCurrentUserWorkoutProgramDayTaskByKey: WorkoutProgramDayTask;
  getCustomerioUser?: Maybe<CustomerioUser>;
  /** Get an exercise by its url-friendly slug */
  getExerciseBySlug?: Maybe<Exercise>;
  getExerciseImportStatus?: Maybe<ImportStatus>;
  getExercisesImportStatus?: Maybe<ImportStatus>;
  /** Get all feature flags and whether or not they are enabled */
  getFeatureFlags: Array<FeatureFlag>;
  getLeadByUuid?: Maybe<Lead>;
  getPatientById: Patient;
  getPatientWorkoutById?: Maybe<PatientWorkout>;
  getPatientWorkoutBySlug?: Maybe<PatientWorkout>;
  getPatientWorkoutsByProviderId?: Maybe<Array<PatientWorkout>>;
  /** Get a list of patients for the current provider */
  getPatients?: Maybe<PatientResults>;
  getProviders?: Maybe<ProviderResults>;
  /** Get recommended subscription plan based on health history */
  getRecommendedSubscriptionPlan: RecommendedPlan;
  /** Given profile items, get a recommended workout persona  */
  getRecommendedWorkoutPersonaFromFitnessProfile?: Maybe<RecommendedWorkoutProgram>;
  getRecommendedWorkoutPersonaFromHealthHistory?: Maybe<RecommendedWorkoutProgram>;
  /** Get a shareable by code */
  getShareableByCode?: Maybe<Shareable>;
  getStripePriceByIds?: Maybe<Array<StripePrice>>;
  getSubscriptionPlanChangeSummary?: Maybe<SubscriptionPlanChangeSummary>;
  getUserNotificationsByUserId?: Maybe<Array<UserNotification>>;
  getUserSubscriptionByUserId?: Maybe<Subscription>;
  getWorkoutById?: Maybe<Workout>;
  /** Get a workout by its url-friendly slug */
  getWorkoutBySlug?: Maybe<Workout>;
  /** Get the status of a single workout import */
  getWorkoutImportStatus: ImportStatus;
  getWorkoutProgramById: WorkoutProgram;
  getWorkoutsBySlugs?: Maybe<Array<Workout>>;
  /** Get the status of the workouts import (for all workouts) */
  getWorkoutsImportStatus?: Maybe<ImportStatus>;
  searchProviders: SearchResult;
};


/** Base query type that defines all queries */
export type QueryGetCurrentUserAssessmentResultByIdArgs = {
  id: Scalars['ID'];
};


/** Base query type that defines all queries */
export type QueryGetCurrentUserAssessmentResultBySlugAndTaskIdArgs = {
  slug: Scalars['String'];
  taskId?: InputMaybe<Scalars['String']>;
};


/** Base query type that defines all queries */
export type QueryGetCurrentUserCartArgs = {
  currencyCode?: InputMaybe<Scalars['String']>;
  promoCode?: InputMaybe<Scalars['String']>;
  stripePriceId: Scalars['String'];
};


/** Base query type that defines all queries */
export type QueryGetCurrentUserPaymentHistoryArgs = {
  page?: InputMaybe<Scalars['String']>;
};


/** Base query type that defines all queries */
export type QueryGetCurrentUserPersonalizationSwapsArgs = {
  swapContextSlug?: InputMaybe<Scalars['String']>;
  swapContextType?: InputMaybe<Scalars['String']>;
};


/** Base query type that defines all queries */
export type QueryGetCurrentUserWorkoutProgramDayByKeyArgs = {
  key: Scalars['String'];
};


/** Base query type that defines all queries */
export type QueryGetCurrentUserWorkoutProgramDayTaskByKeyArgs = {
  key: Scalars['String'];
};


/** Base query type that defines all queries */
export type QueryGetCustomerioUserArgs = {
  cioId: Scalars['String'];
};


/** Base query type that defines all queries */
export type QueryGetExerciseBySlugArgs = {
  slug: Scalars['String'];
};


/** Base query type that defines all queries */
export type QueryGetExerciseImportStatusArgs = {
  slug: Scalars['String'];
};


/** Base query type that defines all queries */
export type QueryGetLeadByUuidArgs = {
  uuid: Scalars['ID'];
};


/** Base query type that defines all queries */
export type QueryGetPatientByIdArgs = {
  id: Scalars['ID'];
};


/** Base query type that defines all queries */
export type QueryGetPatientWorkoutByIdArgs = {
  id: Scalars['ID'];
};


/** Base query type that defines all queries */
export type QueryGetPatientWorkoutBySlugArgs = {
  slug: Scalars['String'];
};


/** Base query type that defines all queries */
export type QueryGetPatientWorkoutsByProviderIdArgs = {
  providerId: Scalars['ID'];
};


/** Base query type that defines all queries */
export type QueryGetPatientsArgs = {
  filter?: InputMaybe<PatientSearchFilters>;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
};


/** Base query type that defines all queries */
export type QueryGetProvidersArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
};


/** Base query type that defines all queries */
export type QueryGetRecommendedSubscriptionPlanArgs = {
  healthHistory: Array<ProfileItemAttributes>;
};


/** Base query type that defines all queries */
export type QueryGetRecommendedWorkoutPersonaFromFitnessProfileArgs = {
  fitness: Array<ProfileItemAttributes>;
};


/** Base query type that defines all queries */
export type QueryGetRecommendedWorkoutPersonaFromHealthHistoryArgs = {
  healthHistory: Array<ProfileItemAttributes>;
};


/** Base query type that defines all queries */
export type QueryGetShareableByCodeArgs = {
  code: Scalars['String'];
};


/** Base query type that defines all queries */
export type QueryGetStripePriceByIdsArgs = {
  countryCode?: InputMaybe<Scalars['String']>;
  currencyCode?: InputMaybe<Scalars['String']>;
  stripePriceIds: Array<Scalars['String']>;
};


/** Base query type that defines all queries */
export type QueryGetSubscriptionPlanChangeSummaryArgs = {
  stripePriceId: Scalars['String'];
  subscriptionId: Scalars['ID'];
};


/** Base query type that defines all queries */
export type QueryGetUserNotificationsByUserIdArgs = {
  userId: Scalars['ID'];
};


/** Base query type that defines all queries */
export type QueryGetUserSubscriptionByUserIdArgs = {
  userId: Scalars['ID'];
};


/** Base query type that defines all queries */
export type QueryGetWorkoutByIdArgs = {
  id: Scalars['ID'];
};


/** Base query type that defines all queries */
export type QueryGetWorkoutBySlugArgs = {
  slug: Scalars['String'];
};


/** Base query type that defines all queries */
export type QueryGetWorkoutImportStatusArgs = {
  slug: Scalars['String'];
};


/** Base query type that defines all queries */
export type QueryGetWorkoutProgramByIdArgs = {
  id: Scalars['ID'];
};


/** Base query type that defines all queries */
export type QueryGetWorkoutsBySlugsArgs = {
  slugs: Array<Scalars['String']>;
};


/** Base query type that defines all queries */
export type QuerySearchProvidersArgs = {
  hasVideoVisits?: InputMaybe<Scalars['Boolean']>;
  inNetwork?: InputMaybe<Scalars['Boolean']>;
  insurance?: InputMaybe<Scalars['String']>;
  location: Scalars['String'];
  providerName?: InputMaybe<Scalars['String']>;
};

/** A type for a user's recommended plan */
export type RecommendedPlan = {
  __typename?: 'RecommendedPlan';
  planType?: Maybe<PlanType>;
};

export type RecommendedWorkoutProgram = {
  __typename?: 'RecommendedWorkoutProgram';
  workoutPersona: WorkoutPersona;
  workoutType: WorkoutType;
};

/** Autogenerated return type of RequestPasswordReset. */
export type RequestPasswordResetPayload = {
  __typename?: 'RequestPasswordResetPayload';
  message: Scalars['String'];
};

export type SearchResult = {
  __typename?: 'SearchResult';
  count?: Maybe<Scalars['Int']>;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  input?: Maybe<Scalars['JSON']>;
  records?: Maybe<Scalars['JSON']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type Shareable = {
  __typename?: 'Shareable';
  code?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  deletedAt?: Maybe<Scalars['ISO8601DateTime']>;
  expiresAt?: Maybe<Scalars['ISO8601DateTime']>;
  firstName: Scalars['String'];
  id: Scalars['ID'];
  isExpired: Scalars['Boolean'];
  shareable: ShareableType;
  shortUrl?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

/** Shareable types can be many things */
export type ShareableType = Workout;

/** Autogenerated return type of StartOnboarding. */
export type StartOnboardingPayload = {
  __typename?: 'StartOnboardingPayload';
  message: Scalars['String'];
};

/** A Stripe setup intent object */
export type StripeCharge = {
  __typename?: 'StripeCharge';
  amount: Scalars['Int'];
  amountDisplay: Scalars['String'];
  createdAt: Scalars['ISO8601DateTime'];
  currency: Scalars['String'];
  id: Scalars['ID'];
  receiptPdf?: Maybe<Scalars['String']>;
  receiptUrl: Scalars['String'];
  status: Scalars['String'];
};

export type StripePrice = {
  __typename?: 'StripePrice';
  currency: Scalars['String'];
  currencySymbol: Scalars['String'];
  id: Scalars['ID'];
  interval: Interval;
  intervalLength: Scalars['Int'];
  msrp: Scalars['Int'];
  msrpDisplay: Scalars['String'];
  unitPrice: Scalars['Int'];
  unitPriceDisplay: Scalars['String'];
  unitPricePerMonth: Scalars['Int'];
  unitPricePerMonthDisplay: Scalars['String'];
};

export type StripeResults = {
  __typename?: 'StripeResults';
  data: Array<StripeCharge>;
  hasMore: Scalars['Boolean'];
  nextPage?: Maybe<Scalars['String']>;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Stripe setup intent object */
export type StripeSetupIntent = {
  __typename?: 'StripeSetupIntent';
  clientSecret: Scalars['String'];
};

export type Subscription = {
  __typename?: 'Subscription';
  activeAt?: Maybe<Scalars['ISO8601DateTime']>;
  cancelAt?: Maybe<Scalars['ISO8601DateTime']>;
  canceledAt?: Maybe<Scalars['ISO8601DateTime']>;
  createdAt: Scalars['ISO8601DateTime'];
  currencyCode: Scalars['String'];
  hasPayment: Scalars['Boolean'];
  id: Scalars['ID'];
  interval?: Maybe<Interval>;
  metadata?: Maybe<Scalars['JSON']>;
  planType?: Maybe<PlanType>;
  state?: Maybe<Scalars['String']>;
  stripePriceId?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  trialDaysRemaining?: Maybe<Scalars['Int']>;
  trialEndsAt?: Maybe<Scalars['ISO8601DateTime']>;
  trialStartedAt?: Maybe<Scalars['ISO8601DateTime']>;
  updatedAt: Scalars['ISO8601DateTime'];
  userId: Scalars['ID'];
};

/** Attributes for creating a subscription */
export type SubscriptionAttributes = {
  interval?: InputMaybe<Interval>;
  planType?: InputMaybe<PlanType>;
  promoCode?: InputMaybe<Scalars['String']>;
  stripePriceId?: InputMaybe<Scalars['String']>;
  stripeSetupIntentId?: InputMaybe<Scalars['String']>;
  subscriptionId?: InputMaybe<Scalars['ID']>;
};

export type SubscriptionPlanChangeSummary = {
  __typename?: 'SubscriptionPlanChangeSummary';
  cardType: Scalars['String'];
  currencySymbol: Scalars['String'];
  dueNow: Scalars['Float'];
  dueNowDisplay: Scalars['String'];
  id: Scalars['ID'];
  isTrial: Scalars['Boolean'];
  last4: Scalars['String'];
  newPrice: Scalars['Float'];
  newPriceDisplay: Scalars['String'];
  prorationDate: Scalars['ISO8601DateTime'];
  renewalDate: Scalars['ISO8601DateTime'];
};

export type SubscriptionSummary = {
  __typename?: 'SubscriptionSummary';
  billingPeriodEndAt?: Maybe<Scalars['ISO8601DateTime']>;
  cardType?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  displayPrice?: Maybe<Scalars['String']>;
  last4?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
};

export type SwapContext = Workout;

export type Swappable = Exercise;

/** Taskable can be a workout */
export type Taskable = Activity | Assessment | WellTip | Workout;

export enum Time {
  Am = 'AM',
  Pm = 'PM'
}

export enum Timezone {
  Cst = 'CST',
  Est = 'EST',
  Mst = 'MST',
  Pst = 'PST'
}

export type User = {
  __typename?: 'User';
  /** Number of completed assessments */
  assessmentsCompleted: Scalars['Int'];
  authProviders?: Maybe<Array<UserAuthProvider>>;
  /** When the user was created */
  createdAt: Scalars['ISO8601DateTime'];
  /** The users current workout persona */
  currentWorkoutPersona?: Maybe<UserWorkoutPersona>;
  currentWorkoutProgram?: Maybe<WorkoutProgram>;
  /** The users current workout type */
  currentWorkoutType?: Maybe<WorkoutType>;
  /** Email address pf the user */
  email?: Maybe<Scalars['String']>;
  /** Sha256 hash of the users email address */
  emailHash: Scalars['String'];
  /** First name of the user */
  firstName?: Maybe<Scalars['String']>;
  /** Whether or not a user has accepted the disclaimer */
  hasAcceptedDisclaimer: Scalars['Boolean'];
  /**
   * Whether or not the user has seen the welcome message
   *                                               via the modal
   */
  hasSeenWelcomeMessage: Scalars['Boolean'];
  hasSubscription: Scalars['Boolean'];
  /** Internal id for the user */
  id: Scalars['ID'];
  intercomHash?: Maybe<Scalars['String']>;
  /** Whether or not a user has a draft subscription */
  isDraft: Scalars['Boolean'];
  /** Whether or not a user has onboarded through intake */
  isOnboarded: Scalars['Boolean'];
  /** Whether or not the user has a payment failure */
  isPaymentFailure: Scalars['Boolean'];
  /** Whether or not the user is subscribed */
  isSubscribed: Scalars['Boolean'];
  /** Whether or not a user has an expired trial and is not subscribed */
  isTrialExpired: Scalars['Boolean'];
  /** Whether or not a user is trialing a subscription */
  isTrialing: Scalars['Boolean'];
  /** Whether or not the user is subscribed */
  isUnsubscribed: Scalars['Boolean'];
  /** Last name of the user */
  lastName?: Maybe<Scalars['String']>;
  /** Users music preference */
  musicPreferences?: Maybe<MusicPreference>;
  /** Phone number for the user */
  phone?: Maybe<Scalars['String']>;
  /** Providers the user is associated with */
  providers?: Maybe<Array<Provider>>;
  /** Last time the user was updated */
  updatedAt: Scalars['ISO8601DateTime'];
  userProfile?: Maybe<UserProfile>;
  /** Number of completed programs */
  workoutProgramsCompleted: Scalars['Int'];
};

export type UserAssessmentResult = {
  __typename?: 'UserAssessmentResult';
  assessment: Assessment;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  metadata?: Maybe<Scalars['JSON']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

/** Attributes for creating a new user assessment result */
export type UserAssessmentResultAttributes = {
  /** Assessment id to log results for */
  assessmentId: Scalars['ID'];
  /** Metadata where results live */
  metadata?: InputMaybe<Scalars['JSON']>;
};

/** Attributes for creating or updating a user */
export type UserAttributes = {
  authProvider: AuthProviderAttributes;
  email: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  hasAcceptedDisclaimer?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['String']>;
};

export type UserAuthProvider = {
  __typename?: 'UserAuthProvider';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  provider?: Maybe<Scalars['String']>;
  uid?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
  userId: Scalars['ID'];
};

export type UserNotification = {
  __typename?: 'UserNotification';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  isSubscribed?: Maybe<Scalars['Boolean']>;
  isUnsubscribed?: Maybe<Scalars['Boolean']>;
  metadata?: Maybe<UserNotificationMetadata>;
  notificationMethod: UserNotificationMethod;
  notificationType?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['ISO8601DateTime']>;
  unsubscribedAt?: Maybe<Scalars['ISO8601DateTime']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type UserNotificationMetadata = {
  __typename?: 'UserNotificationMetadata';
  notificationDays?: Maybe<Array<Day>>;
  notificationHour?: Maybe<Scalars['Int']>;
  notificationMinute?: Maybe<Scalars['Int']>;
  notificationTime?: Maybe<Time>;
  notificationTimezone?: Maybe<Timezone>;
};

export type UserNotificationMetadataAttributes = {
  notificationDays: Array<Day>;
  notificationHour: Scalars['Int'];
  notificationMinute: Scalars['Int'];
  notificationTime: Time;
  notificationTimezone: Timezone;
};

export enum UserNotificationMethod {
  Email = 'EMAIL',
  Text = 'TEXT',
  TextAndEmail = 'TEXT_AND_EMAIL'
}

export type UserPersonalizationSwap = {
  __typename?: 'UserPersonalizationSwap';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  replace: Swappable;
  swapContext?: Maybe<SwapContext>;
  target: Swappable;
  updatedAt: Scalars['ISO8601DateTime'];
  userId: Scalars['ID'];
};

/** Attributes for creating a personalization swap */
export type UserPersonalizationSwapAttributes = {
  replaceSlug: Scalars['String'];
  replaceType: Scalars['String'];
  swapContextSlug: Scalars['String'];
  swapContextType: Scalars['String'];
  targetSlug: Scalars['String'];
  targetType: Scalars['String'];
};

export type UserProfile = {
  __typename?: 'UserProfile';
  active?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['ISO8601DateTime'];
  fitness?: Maybe<Profile>;
  goals?: Maybe<Profile>;
  healthHistory?: Maybe<Profile>;
  id: Scalars['ID'];
  updatedAt: Scalars['ISO8601DateTime'];
};

/** Attributes for creating or updating a user profile */
export type UserProfileAttributes = {
  fitness?: InputMaybe<Array<ProfileItemAttributes>>;
  goals?: InputMaybe<Array<ProfileItemAttributes>>;
  healthHistory?: InputMaybe<Array<ProfileItemAttributes>>;
};

/** Attributes for creating or updating a user */
export type UserUpdateAttributes = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
};

export type UserWellTipResult = {
  __typename?: 'UserWellTipResult';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  metadata?: Maybe<Scalars['JSON']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

/** Attributes for creating a new user well tip result */
export type UserWellTipResultAttributes = {
  /** Metadata where results live */
  metadata?: InputMaybe<Scalars['JSON']>;
  /** Well tip id to log results for */
  wellTipId: Scalars['ID'];
};

/** A user workout persona */
export type UserWorkoutPersona = {
  __typename?: 'UserWorkoutPersona';
  createdAt: Scalars['ISO8601DateTime'];
  deletedAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  updatedAt: Scalars['ISO8601DateTime'];
  userId?: Maybe<Scalars['ID']>;
  workoutPersona: WorkoutPersona;
  workoutPersonaId?: Maybe<Scalars['ID']>;
};

/** Autogenerated return type of ValidatePasswordResetToken. */
export type ValidatePasswordResetTokenPayload = {
  __typename?: 'ValidatePasswordResetTokenPayload';
  message: Scalars['String'];
};

/** Autogenerated return type of ValidatePromoCode. */
export type ValidatePromoCodePayload = {
  __typename?: 'ValidatePromoCodePayload';
  isValid: Scalars['Boolean'];
};

export type WaitlistInterest = {
  __typename?: 'WaitlistInterest';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  metadata?: Maybe<Scalars['JSON']>;
  updatedAt: Scalars['ISO8601DateTime'];
  waitlistType?: Maybe<Scalars['String']>;
};

export type WellTip = {
  __typename?: 'WellTip';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  slug: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
};

export type Workout = {
  __typename?: 'Workout';
  createdAt: Scalars['ISO8601DateTime'];
  exercises?: Maybe<Array<Exercise>>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  provider?: Maybe<Provider>;
  slug: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
  workoutOrder?: Maybe<Scalars['Int']>;
};

export type WorkoutPersona = {
  __typename?: 'WorkoutPersona';
  createdAt: Scalars['ISO8601DateTime'];
  default?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  slug?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type WorkoutProgram = {
  __typename?: 'WorkoutProgram';
  completedAt?: Maybe<Scalars['ISO8601DateTime']>;
  createdAt: Scalars['ISO8601DateTime'];
  days: Array<WorkoutProgramDay>;
  id: Scalars['ID'];
  isCompleted: Scalars['Boolean'];
  slug: Scalars['String'];
  stats: WorkoutProgramStats;
  updatedAt: Scalars['ISO8601DateTime'];
  userId: Scalars['ID'];
  weeks: Scalars['Int'];
};

export type WorkoutProgramDay = {
  __typename?: 'WorkoutProgramDay';
  completedAt?: Maybe<Scalars['ISO8601DateTime']>;
  createdAt: Scalars['ISO8601DateTime'];
  dayNumber: Scalars['Int'];
  id: Scalars['ID'];
  isCompleted: Scalars['Boolean'];
  isLocked: Scalars['Boolean'];
  isStarted: Scalars['Boolean'];
  key?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  startedAt?: Maybe<Scalars['ISO8601DateTime']>;
  tasks: Array<WorkoutProgramDayTask>;
  updatedAt: Scalars['ISO8601DateTime'];
  weekNumber: Scalars['Int'];
};

export type WorkoutProgramDayTask = {
  __typename?: 'WorkoutProgramDayTask';
  createdAt: Scalars['ISO8601DateTime'];
  /** true */
  day: WorkoutProgramDay;
  id: Scalars['ID'];
  isCompleted: Scalars['Boolean'];
  isStarted: Scalars['Boolean'];
  key?: Maybe<Scalars['String']>;
  taskable: Taskable;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type WorkoutProgramStats = {
  __typename?: 'WorkoutProgramStats';
  weeksCompleted: Scalars['Int'];
  weeksRemaining: Scalars['Int'];
  workoutsCompleted: Scalars['Int'];
  workoutsRemaining: Scalars['Int'];
};

export type WorkoutRating = {
  __typename?: 'WorkoutRating';
  createdAt: Scalars['ISO8601DateTime'];
  difficultyRating?: Maybe<Scalars['Int']>;
  generalFeedback?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  instructorRating?: Maybe<Scalars['Int']>;
  musicRating?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['ISO8601DateTime'];
  voiceRating?: Maybe<Scalars['Int']>;
};

/** Attributes for creating a user workout rating */
export type WorkoutRatingAttributes = {
  difficultyRating: Scalars['Int'];
  generalFeedback?: InputMaybe<Scalars['String']>;
  instructorRating?: InputMaybe<Scalars['Int']>;
  metdata?: InputMaybe<Scalars['JSON']>;
  musicRating?: InputMaybe<Scalars['Int']>;
  voiceRating?: InputMaybe<Scalars['Int']>;
  workoutId: Scalars['ID'];
};

export type WorkoutTemplate = {
  __typename?: 'WorkoutTemplate';
  /** When the template was created */
  createdAt: Scalars['ISO8601DateTime'];
  exercises: Array<WorkoutTemplateExercise>;
  /** The ID of this template */
  id: Scalars['ID'];
  /** The name of this template */
  name?: Maybe<Scalars['String']>;
  /** The provider that owns this template */
  provider: Provider;
  /** The URL-safe slug of this template */
  slug: Scalars['String'];
  /** When the template was last updated */
  updatedAt: Scalars['ISO8601DateTime'];
  workoutOrder?: Maybe<Scalars['Int']>;
  workoutPersona: WorkoutPersona;
};

/** Attributes for creating a workout template */
export type WorkoutTemplateAttributes = {
  /** The exercises in this template */
  exercises: Array<WorkoutTemplateExerciseAttributes>;
  /** The name of this template */
  name: Scalars['String'];
};

export type WorkoutTemplateExercise = {
  __typename?: 'WorkoutTemplateExercise';
  createdAt: Scalars['ISO8601DateTime'];
  deletedAt?: Maybe<Scalars['ISO8601DateTime']>;
  holdDuration?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  instructions?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['JSON']>;
  order?: Maybe<Scalars['Int']>;
  reps?: Maybe<Scalars['Int']>;
  restDuration?: Maybe<Scalars['Int']>;
  sets?: Maybe<Scalars['Int']>;
  slug: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
};

/** Attributes for creating a workout template exercise */
export type WorkoutTemplateExerciseAttributes = {
  /** The duration to hold the exercise */
  holdDuration?: InputMaybe<Scalars['Int']>;
  /** The instructions of the exercise */
  instructions?: InputMaybe<Scalars['String']>;
  /** The number of reps to perform */
  reps: Scalars['Int'];
  /** The duration to rest between sets */
  restDuration?: InputMaybe<Scalars['Int']>;
  /** The number of sets to perform */
  sets: Scalars['Int'];
  /** The slug of the exercise */
  slug: Scalars['String'];
};

export enum WorkoutType {
  All = 'ALL',
  Base = 'BASE',
  Resistance = 'RESISTANCE',
  Weight = 'WEIGHT',
  WeightResistanceStepLoop = 'WEIGHT_RESISTANCE_STEP_LOOP',
  WeightStep = 'WEIGHT_STEP',
  WeightStepNoProne = 'WEIGHT_STEP_NO_PRONE'
}

export type WorkoutFragmentFragment = { __typename?: 'Workout', id: string, slug: string, workoutOrder?: number | null, createdAt: any, updatedAt: any };

export type AssessmentFragmentFragment = { __typename?: 'Assessment', id: string, slug: string, createdAt: any, updatedAt: any };

export type ActivityFragmentFragment = { __typename?: 'Activity', id: string, slug: string, createdAt: any, updatedAt: any };

export type WellTipFragmentFragment = { __typename?: 'WellTip', id: string, slug: string, createdAt: any, updatedAt: any };

export type ProfileFragmentFragment = { __typename?: 'Profile', id: string, profileType?: string | null, createdAt: any, updatedAt: any, items?: Array<{ __typename?: 'ProfileItem', id: string, key: string, value: string, createdAt: any, updatedAt: any }> | null };

export type ProfileItemFragmentFragment = { __typename?: 'ProfileItem', id: string, key: string, value: string, createdAt: any, updatedAt: any };

export type UserProfileFragmentFragment = { __typename?: 'UserProfile', id: string, createdAt: any, updatedAt: any, fitness?: { __typename?: 'Profile', id: string, profileType?: string | null, createdAt: any, updatedAt: any, items?: Array<{ __typename?: 'ProfileItem', id: string, key: string, value: string, createdAt: any, updatedAt: any }> | null } | null, healthHistory?: { __typename?: 'Profile', id: string, profileType?: string | null, createdAt: any, updatedAt: any, items?: Array<{ __typename?: 'ProfileItem', id: string, key: string, value: string, createdAt: any, updatedAt: any }> | null } | null, goals?: { __typename?: 'Profile', id: string, profileType?: string | null, createdAt: any, updatedAt: any, items?: Array<{ __typename?: 'ProfileItem', id: string, key: string, value: string, createdAt: any, updatedAt: any }> | null } | null };

export type WorkoutPersonaFragmentFragment = { __typename?: 'WorkoutPersona', id: string, slug?: string | null, updatedAt: any, createdAt: any };

export type UserFragmentFragment = { __typename?: 'User', id: string, email?: string | null, firstName?: string | null, lastName?: string | null, phone?: string | null, isOnboarded: boolean, isSubscribed: boolean, isTrialExpired: boolean, isTrialing: boolean, isUnsubscribed: boolean, isDraft: boolean, hasAcceptedDisclaimer: boolean, hasSeenWelcomeMessage: boolean, currentWorkoutType?: WorkoutType | null, createdAt: any, updatedAt: any, currentWorkoutProgram?: { __typename?: 'WorkoutProgram', id: string } | null, currentWorkoutPersona?: { __typename?: 'UserWorkoutPersona', id: string, workoutPersona: { __typename?: 'WorkoutPersona', id: string, slug?: string | null } } | null, authProviders?: Array<{ __typename?: 'UserAuthProvider', id: string, provider?: string | null, uid?: string | null }> | null, userProfile?: { __typename?: 'UserProfile', id: string, createdAt: any, updatedAt: any, fitness?: { __typename?: 'Profile', id: string, profileType?: string | null, createdAt: any, updatedAt: any, items?: Array<{ __typename?: 'ProfileItem', id: string, key: string, value: string, createdAt: any, updatedAt: any }> | null } | null, healthHistory?: { __typename?: 'Profile', id: string, profileType?: string | null, createdAt: any, updatedAt: any, items?: Array<{ __typename?: 'ProfileItem', id: string, key: string, value: string, createdAt: any, updatedAt: any }> | null } | null, goals?: { __typename?: 'Profile', id: string, profileType?: string | null, createdAt: any, updatedAt: any, items?: Array<{ __typename?: 'ProfileItem', id: string, key: string, value: string, createdAt: any, updatedAt: any }> | null } | null } | null };

export type ImportWorkoutMutationVariables = Exact<{
  slug: Scalars['String'];
}>;


export type ImportWorkoutMutation = { __typename?: 'Mutation', importWorkout: { __typename?: 'ImportWorkoutPayload', message: string } };

export type ImportWorkoutsMutationVariables = Exact<{ [key: string]: never; }>;


export type ImportWorkoutsMutation = { __typename?: 'Mutation', importWorkouts: { __typename?: 'ImportWorkoutsPayload', message: string } };

export type ImportExerciseMutationVariables = Exact<{
  slug: Scalars['String'];
}>;


export type ImportExerciseMutation = { __typename?: 'Mutation', importExercise: { __typename?: 'ImportExercisePayload', message: string } };

export type ImportExercisesMutationVariables = Exact<{ [key: string]: never; }>;


export type ImportExercisesMutation = { __typename?: 'Mutation', importExercises: { __typename?: 'ImportExercisesPayload', message: string } };

export type UpdateUserAdminMutationVariables = Exact<{
  userId: Scalars['ID'];
  attributes: UserUpdateAttributes;
}>;


export type UpdateUserAdminMutation = { __typename?: 'Mutation', updateUserAdmin: { __typename?: 'User', id: string, email?: string | null, firstName?: string | null, lastName?: string | null, phone?: string | null, isOnboarded: boolean, isSubscribed: boolean, isTrialExpired: boolean, isTrialing: boolean, isUnsubscribed: boolean, isDraft: boolean, hasAcceptedDisclaimer: boolean, hasSeenWelcomeMessage: boolean, currentWorkoutType?: WorkoutType | null, createdAt: any, updatedAt: any, currentWorkoutProgram?: { __typename?: 'WorkoutProgram', id: string } | null, currentWorkoutPersona?: { __typename?: 'UserWorkoutPersona', id: string, workoutPersona: { __typename?: 'WorkoutPersona', id: string, slug?: string | null } } | null, authProviders?: Array<{ __typename?: 'UserAuthProvider', id: string, provider?: string | null, uid?: string | null }> | null, userProfile?: { __typename?: 'UserProfile', id: string, createdAt: any, updatedAt: any, fitness?: { __typename?: 'Profile', id: string, profileType?: string | null, createdAt: any, updatedAt: any, items?: Array<{ __typename?: 'ProfileItem', id: string, key: string, value: string, createdAt: any, updatedAt: any }> | null } | null, healthHistory?: { __typename?: 'Profile', id: string, profileType?: string | null, createdAt: any, updatedAt: any, items?: Array<{ __typename?: 'ProfileItem', id: string, key: string, value: string, createdAt: any, updatedAt: any }> | null } | null, goals?: { __typename?: 'Profile', id: string, profileType?: string | null, createdAt: any, updatedAt: any, items?: Array<{ __typename?: 'ProfileItem', id: string, key: string, value: string, createdAt: any, updatedAt: any }> | null } | null } | null } };

export type ChangePasswordAdminMutationVariables = Exact<{
  userId: Scalars['ID'];
  password: Scalars['String'];
}>;


export type ChangePasswordAdminMutation = { __typename?: 'Mutation', changePasswordAdmin: { __typename?: 'ChangePasswordAdminPayload', message: string } };

export type UpdateUserProfileAdminMutationVariables = Exact<{
  userId: Scalars['ID'];
  attributes: UserProfileAttributes;
}>;


export type UpdateUserProfileAdminMutation = { __typename?: 'Mutation', updateUserProfileAdmin: { __typename?: 'UserProfile', id: string, createdAt: any, updatedAt: any, fitness?: { __typename?: 'Profile', id: string, profileType?: string | null, createdAt: any, updatedAt: any, items?: Array<{ __typename?: 'ProfileItem', id: string, key: string, value: string, createdAt: any, updatedAt: any }> | null } | null, healthHistory?: { __typename?: 'Profile', id: string, profileType?: string | null, createdAt: any, updatedAt: any, items?: Array<{ __typename?: 'ProfileItem', id: string, key: string, value: string, createdAt: any, updatedAt: any }> | null } | null, goals?: { __typename?: 'Profile', id: string, profileType?: string | null, createdAt: any, updatedAt: any, items?: Array<{ __typename?: 'ProfileItem', id: string, key: string, value: string, createdAt: any, updatedAt: any }> | null } | null } };

export type UpdateUserPhoneAdminMutationVariables = Exact<{
  userId: Scalars['ID'];
  phone: Scalars['String'];
}>;


export type UpdateUserPhoneAdminMutation = { __typename?: 'Mutation', updateUserPhoneAdmin: { __typename?: 'User', id: string, email?: string | null, firstName?: string | null, lastName?: string | null, phone?: string | null, isOnboarded: boolean, isSubscribed: boolean, isTrialExpired: boolean, isTrialing: boolean, isUnsubscribed: boolean, isDraft: boolean, hasAcceptedDisclaimer: boolean, hasSeenWelcomeMessage: boolean, currentWorkoutType?: WorkoutType | null, createdAt: any, updatedAt: any, currentWorkoutProgram?: { __typename?: 'WorkoutProgram', id: string } | null, currentWorkoutPersona?: { __typename?: 'UserWorkoutPersona', id: string, workoutPersona: { __typename?: 'WorkoutPersona', id: string, slug?: string | null } } | null, authProviders?: Array<{ __typename?: 'UserAuthProvider', id: string, provider?: string | null, uid?: string | null }> | null, userProfile?: { __typename?: 'UserProfile', id: string, createdAt: any, updatedAt: any, fitness?: { __typename?: 'Profile', id: string, profileType?: string | null, createdAt: any, updatedAt: any, items?: Array<{ __typename?: 'ProfileItem', id: string, key: string, value: string, createdAt: any, updatedAt: any }> | null } | null, healthHistory?: { __typename?: 'Profile', id: string, profileType?: string | null, createdAt: any, updatedAt: any, items?: Array<{ __typename?: 'ProfileItem', id: string, key: string, value: string, createdAt: any, updatedAt: any }> | null } | null, goals?: { __typename?: 'Profile', id: string, profileType?: string | null, createdAt: any, updatedAt: any, items?: Array<{ __typename?: 'ProfileItem', id: string, key: string, value: string, createdAt: any, updatedAt: any }> | null } | null } | null } };

export type PersonalizeWorkoutProgramAdminMutationVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type PersonalizeWorkoutProgramAdminMutation = { __typename?: 'Mutation', personalizeWorkoutProgramAdmin: { __typename?: 'WorkoutProgram', id: string } };

export type SubscribeToNotificationAdminMutationVariables = Exact<{
  userId: Scalars['ID'];
  notificationType: NotificationType;
  notificationMethod: UserNotificationMethod;
  metadata: UserNotificationMetadataAttributes;
}>;


export type SubscribeToNotificationAdminMutation = { __typename?: 'Mutation', subscribeToNotificationAdmin: { __typename?: 'UserNotification', id: string } };

export type UnsubscribeFromNotificationAdminMutationVariables = Exact<{
  userId: Scalars['ID'];
  notificationType: NotificationType;
}>;


export type UnsubscribeFromNotificationAdminMutation = { __typename?: 'Mutation', unsubscribeFromNotificationAdmin: { __typename?: 'UserNotification', id: string } };

export type CompleteWorkoutProgramDayTaskMutationVariables = Exact<{
  id: Scalars['ID'];
  manual?: InputMaybe<Scalars['Boolean']>;
}>;


export type CompleteWorkoutProgramDayTaskMutation = { __typename?: 'Mutation', completeWorkoutProgramDayTask: { __typename?: 'WorkoutProgramDayTask', id: string } };

export type UnlockWorkoutProgramDayAdminMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type UnlockWorkoutProgramDayAdminMutation = { __typename?: 'Mutation', unlockWorkoutProgramDayAdmin: { __typename?: 'WorkoutProgramDay', id: string } };

export type GetFeatureFlagsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetFeatureFlagsQuery = { __typename?: 'Query', getFeatureFlags: Array<{ __typename?: 'FeatureFlag', feature: string, enabled: boolean }> };

export type GetWorkoutBySlugQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type GetWorkoutBySlugQuery = { __typename?: 'Query', getWorkoutBySlug?: { __typename?: 'Workout', id: string, slug: string } | null };

export type GetExerciseBySlugQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type GetExerciseBySlugQuery = { __typename?: 'Query', getExerciseBySlug?: { __typename?: 'Exercise', id: string, slug: string } | null };

export type GetWorkoutsBySlugsQueryVariables = Exact<{
  slugs: Array<Scalars['String']> | Scalars['String'];
}>;


export type GetWorkoutsBySlugsQuery = { __typename?: 'Query', getWorkoutsBySlugs?: Array<{ __typename?: 'Workout', id: string, slug: string, updatedAt: any, provider?: { __typename?: 'Provider', id: string, slug: string, name: string } | null }> | null };

export type GetProvidersQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
}>;


export type GetProvidersQuery = { __typename?: 'Query', getProviders?: { __typename?: 'ProviderResults', totalCount: number, hasMore: boolean, results: Array<{ __typename?: 'Provider', id: string, slug: string, name: string, patientCount?: number | null, programsSent?: number | null, updatedAt: any, createdAt: any }> } | null };

export type GetPatientsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<PatientSearchFilters>;
}>;


export type GetPatientsQuery = { __typename?: 'Query', getPatients?: { __typename?: 'PatientResults', totalCount: number, hasMore: boolean, results: Array<{ __typename?: 'Patient', id: string, firstName: string, lastName: string, email: string, status: PatientStatus, updatedAt: any, createdAt: any, provider: { __typename?: 'Provider', id: string, slug: string, name: string } }> } | null };

export type GetPatientByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetPatientByIdQuery = { __typename?: 'Query', getPatientById: { __typename?: 'Patient', id: string, firstName: string, lastName: string, email: string, status: PatientStatus, provider: { __typename?: 'Provider', id: string, slug: string, name: string }, user?: { __typename?: 'User', id: string, email?: string | null, firstName?: string | null, lastName?: string | null, phone?: string | null, isOnboarded: boolean, isSubscribed: boolean, isTrialExpired: boolean, isTrialing: boolean, isUnsubscribed: boolean, isDraft: boolean, hasAcceptedDisclaimer: boolean, hasSeenWelcomeMessage: boolean, currentWorkoutType?: WorkoutType | null, createdAt: any, updatedAt: any, currentWorkoutProgram?: { __typename?: 'WorkoutProgram', id: string } | null, currentWorkoutPersona?: { __typename?: 'UserWorkoutPersona', id: string, workoutPersona: { __typename?: 'WorkoutPersona', id: string, slug?: string | null } } | null, authProviders?: Array<{ __typename?: 'UserAuthProvider', id: string, provider?: string | null, uid?: string | null }> | null, userProfile?: { __typename?: 'UserProfile', id: string, createdAt: any, updatedAt: any, fitness?: { __typename?: 'Profile', id: string, profileType?: string | null, createdAt: any, updatedAt: any, items?: Array<{ __typename?: 'ProfileItem', id: string, key: string, value: string, createdAt: any, updatedAt: any }> | null } | null, healthHistory?: { __typename?: 'Profile', id: string, profileType?: string | null, createdAt: any, updatedAt: any, items?: Array<{ __typename?: 'ProfileItem', id: string, key: string, value: string, createdAt: any, updatedAt: any }> | null } | null, goals?: { __typename?: 'Profile', id: string, profileType?: string | null, createdAt: any, updatedAt: any, items?: Array<{ __typename?: 'ProfileItem', id: string, key: string, value: string, createdAt: any, updatedAt: any }> | null } | null } | null } | null } };

export type GetWorkoutProgramByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetWorkoutProgramByIdQuery = { __typename?: 'Query', getWorkoutProgramById: { __typename?: 'WorkoutProgram', id: string, weeks: number, isCompleted: boolean, stats: { __typename?: 'WorkoutProgramStats', weeksCompleted: number, weeksRemaining: number, workoutsCompleted: number, workoutsRemaining: number }, days: Array<{ __typename?: 'WorkoutProgramDay', id: string, name: string, key?: string | null, weekNumber: number, dayNumber: number, isLocked: boolean, isCompleted: boolean, isStarted: boolean, tasks: Array<{ __typename?: 'WorkoutProgramDayTask', id: string, key?: string | null, isCompleted: boolean, isStarted: boolean, taskable: { __typename?: 'Activity', id: string, slug: string, createdAt: any, updatedAt: any } | { __typename?: 'Assessment', id: string, slug: string, createdAt: any, updatedAt: any } | { __typename?: 'WellTip', id: string, slug: string, createdAt: any, updatedAt: any } | { __typename?: 'Workout', id: string, slug: string, workoutOrder?: number | null, createdAt: any, updatedAt: any } }> }> } };

export type GetWorkoutImportStatusQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type GetWorkoutImportStatusQuery = { __typename?: 'Query', getWorkoutImportStatus: { __typename?: 'ImportStatus', status: string } };

export type GetWorkoutsImportStatusQueryVariables = Exact<{ [key: string]: never; }>;


export type GetWorkoutsImportStatusQuery = { __typename?: 'Query', getWorkoutsImportStatus?: { __typename?: 'ImportStatus', status: string } | null };

export type GetExerciseImportStatusQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type GetExerciseImportStatusQuery = { __typename?: 'Query', getExerciseImportStatus?: { __typename?: 'ImportStatus', status: string } | null };

export type GetExercisesImportStatusQueryVariables = Exact<{ [key: string]: never; }>;


export type GetExercisesImportStatusQuery = { __typename?: 'Query', getExercisesImportStatus?: { __typename?: 'ImportStatus', status: string } | null };

export type GetRecommendedWorkoutPersonaFromFitnessProfileQueryVariables = Exact<{
  fitness: Array<ProfileItemAttributes> | ProfileItemAttributes;
}>;


export type GetRecommendedWorkoutPersonaFromFitnessProfileQuery = { __typename?: 'Query', getRecommendedWorkoutPersonaFromFitnessProfile?: { __typename?: 'RecommendedWorkoutProgram', workoutType: WorkoutType, workoutPersona: { __typename?: 'WorkoutPersona', id: string, slug?: string | null, updatedAt: any, createdAt: any } } | null };

export type GetUserNotificationsByUserIdQueryVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type GetUserNotificationsByUserIdQuery = { __typename?: 'Query', getUserNotificationsByUserId?: Array<{ __typename?: 'UserNotification', id: string, notificationType?: string | null, notificationMethod: UserNotificationMethod, isSubscribed?: boolean | null, isUnsubscribed?: boolean | null, metadata?: { __typename?: 'UserNotificationMetadata', notificationDays?: Array<Day> | null, notificationHour?: number | null, notificationMinute?: number | null, notificationTime?: Time | null, notificationTimezone?: Timezone | null } | null }> | null };

export type GetUserSubscriptionByUserIdQueryVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type GetUserSubscriptionByUserIdQuery = { __typename?: 'Query', getUserSubscriptionByUserId?: { __typename?: 'Subscription', id: string, planType?: PlanType | null, interval?: Interval | null, state?: string | null, trialEndsAt?: any | null, trialDaysRemaining?: number | null, stripeSubscriptionId?: string | null, cancelAt?: any | null, hasPayment: boolean, currencyCode: string, createdAt: any, updatedAt: any } | null };

export const WorkoutFragmentFragmentDoc = gql`
    fragment WorkoutFragment on Workout {
  id
  slug
  workoutOrder
  createdAt
  updatedAt
}
    `;
export const AssessmentFragmentFragmentDoc = gql`
    fragment AssessmentFragment on Assessment {
  id
  slug
  createdAt
  updatedAt
}
    `;
export const ActivityFragmentFragmentDoc = gql`
    fragment ActivityFragment on Activity {
  id
  slug
  createdAt
  updatedAt
}
    `;
export const WellTipFragmentFragmentDoc = gql`
    fragment WellTipFragment on WellTip {
  id
  slug
  createdAt
  updatedAt
}
    `;
export const WorkoutPersonaFragmentFragmentDoc = gql`
    fragment WorkoutPersonaFragment on WorkoutPersona {
  id
  slug
  updatedAt
  createdAt
}
    `;
export const ProfileItemFragmentFragmentDoc = gql`
    fragment ProfileItemFragment on ProfileItem {
  id
  key
  value
  createdAt
  updatedAt
}
    `;
export const ProfileFragmentFragmentDoc = gql`
    fragment ProfileFragment on Profile {
  id
  profileType
  items {
    ...ProfileItemFragment
  }
  createdAt
  updatedAt
}
    ${ProfileItemFragmentFragmentDoc}`;
export const UserProfileFragmentFragmentDoc = gql`
    fragment UserProfileFragment on UserProfile {
  id
  fitness {
    ...ProfileFragment
  }
  healthHistory {
    ...ProfileFragment
  }
  goals {
    ...ProfileFragment
  }
  createdAt
  updatedAt
}
    ${ProfileFragmentFragmentDoc}`;
export const UserFragmentFragmentDoc = gql`
    fragment UserFragment on User {
  id
  email
  firstName
  lastName
  phone
  currentWorkoutProgram {
    id
  }
  currentWorkoutPersona {
    id
    workoutPersona {
      id
      slug
    }
  }
  authProviders {
    id
    provider
    uid
  }
  userProfile {
    ...UserProfileFragment
  }
  isOnboarded
  isSubscribed
  isTrialExpired
  isTrialing
  isUnsubscribed
  isDraft
  hasAcceptedDisclaimer
  hasSeenWelcomeMessage
  currentWorkoutType
  createdAt
  updatedAt
}
    ${UserProfileFragmentFragmentDoc}`;
export const ImportWorkoutDocument = gql`
    mutation ImportWorkout($slug: String!) {
  importWorkout(slug: $slug) {
    message
  }
}
    `;
export type ImportWorkoutMutationFn = Apollo.MutationFunction<ImportWorkoutMutation, ImportWorkoutMutationVariables>;

/**
 * __useImportWorkoutMutation__
 *
 * To run a mutation, you first call `useImportWorkoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportWorkoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importWorkoutMutation, { data, loading, error }] = useImportWorkoutMutation({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useImportWorkoutMutation(baseOptions?: Apollo.MutationHookOptions<ImportWorkoutMutation, ImportWorkoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImportWorkoutMutation, ImportWorkoutMutationVariables>(ImportWorkoutDocument, options);
      }
export type ImportWorkoutMutationHookResult = ReturnType<typeof useImportWorkoutMutation>;
export type ImportWorkoutMutationResult = Apollo.MutationResult<ImportWorkoutMutation>;
export type ImportWorkoutMutationOptions = Apollo.BaseMutationOptions<ImportWorkoutMutation, ImportWorkoutMutationVariables>;
export const ImportWorkoutsDocument = gql`
    mutation ImportWorkouts {
  importWorkouts {
    message
  }
}
    `;
export type ImportWorkoutsMutationFn = Apollo.MutationFunction<ImportWorkoutsMutation, ImportWorkoutsMutationVariables>;

/**
 * __useImportWorkoutsMutation__
 *
 * To run a mutation, you first call `useImportWorkoutsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportWorkoutsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importWorkoutsMutation, { data, loading, error }] = useImportWorkoutsMutation({
 *   variables: {
 *   },
 * });
 */
export function useImportWorkoutsMutation(baseOptions?: Apollo.MutationHookOptions<ImportWorkoutsMutation, ImportWorkoutsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImportWorkoutsMutation, ImportWorkoutsMutationVariables>(ImportWorkoutsDocument, options);
      }
export type ImportWorkoutsMutationHookResult = ReturnType<typeof useImportWorkoutsMutation>;
export type ImportWorkoutsMutationResult = Apollo.MutationResult<ImportWorkoutsMutation>;
export type ImportWorkoutsMutationOptions = Apollo.BaseMutationOptions<ImportWorkoutsMutation, ImportWorkoutsMutationVariables>;
export const ImportExerciseDocument = gql`
    mutation ImportExercise($slug: String!) {
  importExercise(slug: $slug) {
    message
  }
}
    `;
export type ImportExerciseMutationFn = Apollo.MutationFunction<ImportExerciseMutation, ImportExerciseMutationVariables>;

/**
 * __useImportExerciseMutation__
 *
 * To run a mutation, you first call `useImportExerciseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportExerciseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importExerciseMutation, { data, loading, error }] = useImportExerciseMutation({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useImportExerciseMutation(baseOptions?: Apollo.MutationHookOptions<ImportExerciseMutation, ImportExerciseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImportExerciseMutation, ImportExerciseMutationVariables>(ImportExerciseDocument, options);
      }
export type ImportExerciseMutationHookResult = ReturnType<typeof useImportExerciseMutation>;
export type ImportExerciseMutationResult = Apollo.MutationResult<ImportExerciseMutation>;
export type ImportExerciseMutationOptions = Apollo.BaseMutationOptions<ImportExerciseMutation, ImportExerciseMutationVariables>;
export const ImportExercisesDocument = gql`
    mutation ImportExercises {
  importExercises {
    message
  }
}
    `;
export type ImportExercisesMutationFn = Apollo.MutationFunction<ImportExercisesMutation, ImportExercisesMutationVariables>;

/**
 * __useImportExercisesMutation__
 *
 * To run a mutation, you first call `useImportExercisesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportExercisesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importExercisesMutation, { data, loading, error }] = useImportExercisesMutation({
 *   variables: {
 *   },
 * });
 */
export function useImportExercisesMutation(baseOptions?: Apollo.MutationHookOptions<ImportExercisesMutation, ImportExercisesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImportExercisesMutation, ImportExercisesMutationVariables>(ImportExercisesDocument, options);
      }
export type ImportExercisesMutationHookResult = ReturnType<typeof useImportExercisesMutation>;
export type ImportExercisesMutationResult = Apollo.MutationResult<ImportExercisesMutation>;
export type ImportExercisesMutationOptions = Apollo.BaseMutationOptions<ImportExercisesMutation, ImportExercisesMutationVariables>;
export const UpdateUserAdminDocument = gql`
    mutation UpdateUserAdmin($userId: ID!, $attributes: UserUpdateAttributes!) {
  updateUserAdmin(userId: $userId, attributes: $attributes) {
    ...UserFragment
  }
}
    ${UserFragmentFragmentDoc}`;
export type UpdateUserAdminMutationFn = Apollo.MutationFunction<UpdateUserAdminMutation, UpdateUserAdminMutationVariables>;

/**
 * __useUpdateUserAdminMutation__
 *
 * To run a mutation, you first call `useUpdateUserAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserAdminMutation, { data, loading, error }] = useUpdateUserAdminMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useUpdateUserAdminMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserAdminMutation, UpdateUserAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserAdminMutation, UpdateUserAdminMutationVariables>(UpdateUserAdminDocument, options);
      }
export type UpdateUserAdminMutationHookResult = ReturnType<typeof useUpdateUserAdminMutation>;
export type UpdateUserAdminMutationResult = Apollo.MutationResult<UpdateUserAdminMutation>;
export type UpdateUserAdminMutationOptions = Apollo.BaseMutationOptions<UpdateUserAdminMutation, UpdateUserAdminMutationVariables>;
export const ChangePasswordAdminDocument = gql`
    mutation ChangePasswordAdmin($userId: ID!, $password: String!) {
  changePasswordAdmin(userId: $userId, password: $password) {
    message
  }
}
    `;
export type ChangePasswordAdminMutationFn = Apollo.MutationFunction<ChangePasswordAdminMutation, ChangePasswordAdminMutationVariables>;

/**
 * __useChangePasswordAdminMutation__
 *
 * To run a mutation, you first call `useChangePasswordAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordAdminMutation, { data, loading, error }] = useChangePasswordAdminMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useChangePasswordAdminMutation(baseOptions?: Apollo.MutationHookOptions<ChangePasswordAdminMutation, ChangePasswordAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangePasswordAdminMutation, ChangePasswordAdminMutationVariables>(ChangePasswordAdminDocument, options);
      }
export type ChangePasswordAdminMutationHookResult = ReturnType<typeof useChangePasswordAdminMutation>;
export type ChangePasswordAdminMutationResult = Apollo.MutationResult<ChangePasswordAdminMutation>;
export type ChangePasswordAdminMutationOptions = Apollo.BaseMutationOptions<ChangePasswordAdminMutation, ChangePasswordAdminMutationVariables>;
export const UpdateUserProfileAdminDocument = gql`
    mutation UpdateUserProfileAdmin($userId: ID!, $attributes: UserProfileAttributes!) {
  updateUserProfileAdmin(userId: $userId, attributes: $attributes) {
    ...UserProfileFragment
  }
}
    ${UserProfileFragmentFragmentDoc}`;
export type UpdateUserProfileAdminMutationFn = Apollo.MutationFunction<UpdateUserProfileAdminMutation, UpdateUserProfileAdminMutationVariables>;

/**
 * __useUpdateUserProfileAdminMutation__
 *
 * To run a mutation, you first call `useUpdateUserProfileAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserProfileAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserProfileAdminMutation, { data, loading, error }] = useUpdateUserProfileAdminMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useUpdateUserProfileAdminMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserProfileAdminMutation, UpdateUserProfileAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserProfileAdminMutation, UpdateUserProfileAdminMutationVariables>(UpdateUserProfileAdminDocument, options);
      }
export type UpdateUserProfileAdminMutationHookResult = ReturnType<typeof useUpdateUserProfileAdminMutation>;
export type UpdateUserProfileAdminMutationResult = Apollo.MutationResult<UpdateUserProfileAdminMutation>;
export type UpdateUserProfileAdminMutationOptions = Apollo.BaseMutationOptions<UpdateUserProfileAdminMutation, UpdateUserProfileAdminMutationVariables>;
export const UpdateUserPhoneAdminDocument = gql`
    mutation UpdateUserPhoneAdmin($userId: ID!, $phone: String!) {
  updateUserPhoneAdmin(userId: $userId, phone: $phone) {
    ...UserFragment
  }
}
    ${UserFragmentFragmentDoc}`;
export type UpdateUserPhoneAdminMutationFn = Apollo.MutationFunction<UpdateUserPhoneAdminMutation, UpdateUserPhoneAdminMutationVariables>;

/**
 * __useUpdateUserPhoneAdminMutation__
 *
 * To run a mutation, you first call `useUpdateUserPhoneAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserPhoneAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserPhoneAdminMutation, { data, loading, error }] = useUpdateUserPhoneAdminMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      phone: // value for 'phone'
 *   },
 * });
 */
export function useUpdateUserPhoneAdminMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserPhoneAdminMutation, UpdateUserPhoneAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserPhoneAdminMutation, UpdateUserPhoneAdminMutationVariables>(UpdateUserPhoneAdminDocument, options);
      }
export type UpdateUserPhoneAdminMutationHookResult = ReturnType<typeof useUpdateUserPhoneAdminMutation>;
export type UpdateUserPhoneAdminMutationResult = Apollo.MutationResult<UpdateUserPhoneAdminMutation>;
export type UpdateUserPhoneAdminMutationOptions = Apollo.BaseMutationOptions<UpdateUserPhoneAdminMutation, UpdateUserPhoneAdminMutationVariables>;
export const PersonalizeWorkoutProgramAdminDocument = gql`
    mutation PersonalizeWorkoutProgramAdmin($userId: ID!) {
  personalizeWorkoutProgramAdmin(userId: $userId) {
    id
  }
}
    `;
export type PersonalizeWorkoutProgramAdminMutationFn = Apollo.MutationFunction<PersonalizeWorkoutProgramAdminMutation, PersonalizeWorkoutProgramAdminMutationVariables>;

/**
 * __usePersonalizeWorkoutProgramAdminMutation__
 *
 * To run a mutation, you first call `usePersonalizeWorkoutProgramAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePersonalizeWorkoutProgramAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [personalizeWorkoutProgramAdminMutation, { data, loading, error }] = usePersonalizeWorkoutProgramAdminMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function usePersonalizeWorkoutProgramAdminMutation(baseOptions?: Apollo.MutationHookOptions<PersonalizeWorkoutProgramAdminMutation, PersonalizeWorkoutProgramAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PersonalizeWorkoutProgramAdminMutation, PersonalizeWorkoutProgramAdminMutationVariables>(PersonalizeWorkoutProgramAdminDocument, options);
      }
export type PersonalizeWorkoutProgramAdminMutationHookResult = ReturnType<typeof usePersonalizeWorkoutProgramAdminMutation>;
export type PersonalizeWorkoutProgramAdminMutationResult = Apollo.MutationResult<PersonalizeWorkoutProgramAdminMutation>;
export type PersonalizeWorkoutProgramAdminMutationOptions = Apollo.BaseMutationOptions<PersonalizeWorkoutProgramAdminMutation, PersonalizeWorkoutProgramAdminMutationVariables>;
export const SubscribeToNotificationAdminDocument = gql`
    mutation SubscribeToNotificationAdmin($userId: ID!, $notificationType: NotificationType!, $notificationMethod: UserNotificationMethod!, $metadata: UserNotificationMetadataAttributes!) {
  subscribeToNotificationAdmin(
    userId: $userId
    notificationType: $notificationType
    notificationMethod: $notificationMethod
    metadata: $metadata
  ) {
    id
  }
}
    `;
export type SubscribeToNotificationAdminMutationFn = Apollo.MutationFunction<SubscribeToNotificationAdminMutation, SubscribeToNotificationAdminMutationVariables>;

/**
 * __useSubscribeToNotificationAdminMutation__
 *
 * To run a mutation, you first call `useSubscribeToNotificationAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToNotificationAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [subscribeToNotificationAdminMutation, { data, loading, error }] = useSubscribeToNotificationAdminMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      notificationType: // value for 'notificationType'
 *      notificationMethod: // value for 'notificationMethod'
 *      metadata: // value for 'metadata'
 *   },
 * });
 */
export function useSubscribeToNotificationAdminMutation(baseOptions?: Apollo.MutationHookOptions<SubscribeToNotificationAdminMutation, SubscribeToNotificationAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubscribeToNotificationAdminMutation, SubscribeToNotificationAdminMutationVariables>(SubscribeToNotificationAdminDocument, options);
      }
export type SubscribeToNotificationAdminMutationHookResult = ReturnType<typeof useSubscribeToNotificationAdminMutation>;
export type SubscribeToNotificationAdminMutationResult = Apollo.MutationResult<SubscribeToNotificationAdminMutation>;
export type SubscribeToNotificationAdminMutationOptions = Apollo.BaseMutationOptions<SubscribeToNotificationAdminMutation, SubscribeToNotificationAdminMutationVariables>;
export const UnsubscribeFromNotificationAdminDocument = gql`
    mutation UnsubscribeFromNotificationAdmin($userId: ID!, $notificationType: NotificationType!) {
  unsubscribeFromNotificationAdmin(
    userId: $userId
    notificationType: $notificationType
  ) {
    id
  }
}
    `;
export type UnsubscribeFromNotificationAdminMutationFn = Apollo.MutationFunction<UnsubscribeFromNotificationAdminMutation, UnsubscribeFromNotificationAdminMutationVariables>;

/**
 * __useUnsubscribeFromNotificationAdminMutation__
 *
 * To run a mutation, you first call `useUnsubscribeFromNotificationAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnsubscribeFromNotificationAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unsubscribeFromNotificationAdminMutation, { data, loading, error }] = useUnsubscribeFromNotificationAdminMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      notificationType: // value for 'notificationType'
 *   },
 * });
 */
export function useUnsubscribeFromNotificationAdminMutation(baseOptions?: Apollo.MutationHookOptions<UnsubscribeFromNotificationAdminMutation, UnsubscribeFromNotificationAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnsubscribeFromNotificationAdminMutation, UnsubscribeFromNotificationAdminMutationVariables>(UnsubscribeFromNotificationAdminDocument, options);
      }
export type UnsubscribeFromNotificationAdminMutationHookResult = ReturnType<typeof useUnsubscribeFromNotificationAdminMutation>;
export type UnsubscribeFromNotificationAdminMutationResult = Apollo.MutationResult<UnsubscribeFromNotificationAdminMutation>;
export type UnsubscribeFromNotificationAdminMutationOptions = Apollo.BaseMutationOptions<UnsubscribeFromNotificationAdminMutation, UnsubscribeFromNotificationAdminMutationVariables>;
export const CompleteWorkoutProgramDayTaskDocument = gql`
    mutation CompleteWorkoutProgramDayTask($id: ID!, $manual: Boolean) {
  completeWorkoutProgramDayTask(id: $id, manual: $manual) {
    id
  }
}
    `;
export type CompleteWorkoutProgramDayTaskMutationFn = Apollo.MutationFunction<CompleteWorkoutProgramDayTaskMutation, CompleteWorkoutProgramDayTaskMutationVariables>;

/**
 * __useCompleteWorkoutProgramDayTaskMutation__
 *
 * To run a mutation, you first call `useCompleteWorkoutProgramDayTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteWorkoutProgramDayTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeWorkoutProgramDayTaskMutation, { data, loading, error }] = useCompleteWorkoutProgramDayTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *      manual: // value for 'manual'
 *   },
 * });
 */
export function useCompleteWorkoutProgramDayTaskMutation(baseOptions?: Apollo.MutationHookOptions<CompleteWorkoutProgramDayTaskMutation, CompleteWorkoutProgramDayTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompleteWorkoutProgramDayTaskMutation, CompleteWorkoutProgramDayTaskMutationVariables>(CompleteWorkoutProgramDayTaskDocument, options);
      }
export type CompleteWorkoutProgramDayTaskMutationHookResult = ReturnType<typeof useCompleteWorkoutProgramDayTaskMutation>;
export type CompleteWorkoutProgramDayTaskMutationResult = Apollo.MutationResult<CompleteWorkoutProgramDayTaskMutation>;
export type CompleteWorkoutProgramDayTaskMutationOptions = Apollo.BaseMutationOptions<CompleteWorkoutProgramDayTaskMutation, CompleteWorkoutProgramDayTaskMutationVariables>;
export const UnlockWorkoutProgramDayAdminDocument = gql`
    mutation UnlockWorkoutProgramDayAdmin($id: ID!) {
  unlockWorkoutProgramDayAdmin(id: $id) {
    id
  }
}
    `;
export type UnlockWorkoutProgramDayAdminMutationFn = Apollo.MutationFunction<UnlockWorkoutProgramDayAdminMutation, UnlockWorkoutProgramDayAdminMutationVariables>;

/**
 * __useUnlockWorkoutProgramDayAdminMutation__
 *
 * To run a mutation, you first call `useUnlockWorkoutProgramDayAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnlockWorkoutProgramDayAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unlockWorkoutProgramDayAdminMutation, { data, loading, error }] = useUnlockWorkoutProgramDayAdminMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUnlockWorkoutProgramDayAdminMutation(baseOptions?: Apollo.MutationHookOptions<UnlockWorkoutProgramDayAdminMutation, UnlockWorkoutProgramDayAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnlockWorkoutProgramDayAdminMutation, UnlockWorkoutProgramDayAdminMutationVariables>(UnlockWorkoutProgramDayAdminDocument, options);
      }
export type UnlockWorkoutProgramDayAdminMutationHookResult = ReturnType<typeof useUnlockWorkoutProgramDayAdminMutation>;
export type UnlockWorkoutProgramDayAdminMutationResult = Apollo.MutationResult<UnlockWorkoutProgramDayAdminMutation>;
export type UnlockWorkoutProgramDayAdminMutationOptions = Apollo.BaseMutationOptions<UnlockWorkoutProgramDayAdminMutation, UnlockWorkoutProgramDayAdminMutationVariables>;
export const GetFeatureFlagsDocument = gql`
    query GetFeatureFlags {
  getFeatureFlags {
    feature
    enabled
  }
}
    `;

/**
 * __useGetFeatureFlagsQuery__
 *
 * To run a query within a React component, call `useGetFeatureFlagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeatureFlagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeatureFlagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFeatureFlagsQuery(baseOptions?: Apollo.QueryHookOptions<GetFeatureFlagsQuery, GetFeatureFlagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFeatureFlagsQuery, GetFeatureFlagsQueryVariables>(GetFeatureFlagsDocument, options);
      }
export function useGetFeatureFlagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFeatureFlagsQuery, GetFeatureFlagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFeatureFlagsQuery, GetFeatureFlagsQueryVariables>(GetFeatureFlagsDocument, options);
        }
export type GetFeatureFlagsQueryHookResult = ReturnType<typeof useGetFeatureFlagsQuery>;
export type GetFeatureFlagsLazyQueryHookResult = ReturnType<typeof useGetFeatureFlagsLazyQuery>;
export type GetFeatureFlagsQueryResult = Apollo.QueryResult<GetFeatureFlagsQuery, GetFeatureFlagsQueryVariables>;
export const GetWorkoutBySlugDocument = gql`
    query GetWorkoutBySlug($slug: String!) {
  getWorkoutBySlug(slug: $slug) {
    id
    slug
  }
}
    `;

/**
 * __useGetWorkoutBySlugQuery__
 *
 * To run a query within a React component, call `useGetWorkoutBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkoutBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkoutBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGetWorkoutBySlugQuery(baseOptions: Apollo.QueryHookOptions<GetWorkoutBySlugQuery, GetWorkoutBySlugQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWorkoutBySlugQuery, GetWorkoutBySlugQueryVariables>(GetWorkoutBySlugDocument, options);
      }
export function useGetWorkoutBySlugLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWorkoutBySlugQuery, GetWorkoutBySlugQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWorkoutBySlugQuery, GetWorkoutBySlugQueryVariables>(GetWorkoutBySlugDocument, options);
        }
export type GetWorkoutBySlugQueryHookResult = ReturnType<typeof useGetWorkoutBySlugQuery>;
export type GetWorkoutBySlugLazyQueryHookResult = ReturnType<typeof useGetWorkoutBySlugLazyQuery>;
export type GetWorkoutBySlugQueryResult = Apollo.QueryResult<GetWorkoutBySlugQuery, GetWorkoutBySlugQueryVariables>;
export const GetExerciseBySlugDocument = gql`
    query GetExerciseBySlug($slug: String!) {
  getExerciseBySlug(slug: $slug) {
    id
    slug
  }
}
    `;

/**
 * __useGetExerciseBySlugQuery__
 *
 * To run a query within a React component, call `useGetExerciseBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExerciseBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExerciseBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGetExerciseBySlugQuery(baseOptions: Apollo.QueryHookOptions<GetExerciseBySlugQuery, GetExerciseBySlugQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetExerciseBySlugQuery, GetExerciseBySlugQueryVariables>(GetExerciseBySlugDocument, options);
      }
export function useGetExerciseBySlugLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetExerciseBySlugQuery, GetExerciseBySlugQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetExerciseBySlugQuery, GetExerciseBySlugQueryVariables>(GetExerciseBySlugDocument, options);
        }
export type GetExerciseBySlugQueryHookResult = ReturnType<typeof useGetExerciseBySlugQuery>;
export type GetExerciseBySlugLazyQueryHookResult = ReturnType<typeof useGetExerciseBySlugLazyQuery>;
export type GetExerciseBySlugQueryResult = Apollo.QueryResult<GetExerciseBySlugQuery, GetExerciseBySlugQueryVariables>;
export const GetWorkoutsBySlugsDocument = gql`
    query GetWorkoutsBySlugs($slugs: [String!]!) {
  getWorkoutsBySlugs(slugs: $slugs) {
    id
    slug
    provider {
      id
      slug
      name
    }
    updatedAt
  }
}
    `;

/**
 * __useGetWorkoutsBySlugsQuery__
 *
 * To run a query within a React component, call `useGetWorkoutsBySlugsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkoutsBySlugsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkoutsBySlugsQuery({
 *   variables: {
 *      slugs: // value for 'slugs'
 *   },
 * });
 */
export function useGetWorkoutsBySlugsQuery(baseOptions: Apollo.QueryHookOptions<GetWorkoutsBySlugsQuery, GetWorkoutsBySlugsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWorkoutsBySlugsQuery, GetWorkoutsBySlugsQueryVariables>(GetWorkoutsBySlugsDocument, options);
      }
export function useGetWorkoutsBySlugsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWorkoutsBySlugsQuery, GetWorkoutsBySlugsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWorkoutsBySlugsQuery, GetWorkoutsBySlugsQueryVariables>(GetWorkoutsBySlugsDocument, options);
        }
export type GetWorkoutsBySlugsQueryHookResult = ReturnType<typeof useGetWorkoutsBySlugsQuery>;
export type GetWorkoutsBySlugsLazyQueryHookResult = ReturnType<typeof useGetWorkoutsBySlugsLazyQuery>;
export type GetWorkoutsBySlugsQueryResult = Apollo.QueryResult<GetWorkoutsBySlugsQuery, GetWorkoutsBySlugsQueryVariables>;
export const GetProvidersDocument = gql`
    query GetProviders($limit: Int, $page: Int, $search: String) {
  getProviders(limit: $limit, page: $page, search: $search) {
    results {
      id
      slug
      name
      patientCount
      programsSent
      updatedAt
      createdAt
    }
    totalCount
    hasMore
  }
}
    `;

/**
 * __useGetProvidersQuery__
 *
 * To run a query within a React component, call `useGetProvidersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProvidersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProvidersQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGetProvidersQuery(baseOptions?: Apollo.QueryHookOptions<GetProvidersQuery, GetProvidersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProvidersQuery, GetProvidersQueryVariables>(GetProvidersDocument, options);
      }
export function useGetProvidersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProvidersQuery, GetProvidersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProvidersQuery, GetProvidersQueryVariables>(GetProvidersDocument, options);
        }
export type GetProvidersQueryHookResult = ReturnType<typeof useGetProvidersQuery>;
export type GetProvidersLazyQueryHookResult = ReturnType<typeof useGetProvidersLazyQuery>;
export type GetProvidersQueryResult = Apollo.QueryResult<GetProvidersQuery, GetProvidersQueryVariables>;
export const GetPatientsDocument = gql`
    query GetPatients($limit: Int, $page: Int, $search: String, $filter: PatientSearchFilters) {
  getPatients(limit: $limit, page: $page, search: $search, filter: $filter) {
    results {
      id
      firstName
      lastName
      email
      status
      provider {
        id
        slug
        name
      }
      updatedAt
      createdAt
    }
    totalCount
    hasMore
  }
}
    `;

/**
 * __useGetPatientsQuery__
 *
 * To run a query within a React component, call `useGetPatientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPatientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPatientsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *      search: // value for 'search'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetPatientsQuery(baseOptions?: Apollo.QueryHookOptions<GetPatientsQuery, GetPatientsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPatientsQuery, GetPatientsQueryVariables>(GetPatientsDocument, options);
      }
export function useGetPatientsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPatientsQuery, GetPatientsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPatientsQuery, GetPatientsQueryVariables>(GetPatientsDocument, options);
        }
export type GetPatientsQueryHookResult = ReturnType<typeof useGetPatientsQuery>;
export type GetPatientsLazyQueryHookResult = ReturnType<typeof useGetPatientsLazyQuery>;
export type GetPatientsQueryResult = Apollo.QueryResult<GetPatientsQuery, GetPatientsQueryVariables>;
export const GetPatientByIdDocument = gql`
    query GetPatientById($id: ID!) {
  getPatientById(id: $id) {
    id
    firstName
    lastName
    email
    status
    provider {
      id
      slug
      name
    }
    user {
      ...UserFragment
    }
  }
}
    ${UserFragmentFragmentDoc}`;

/**
 * __useGetPatientByIdQuery__
 *
 * To run a query within a React component, call `useGetPatientByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPatientByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPatientByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPatientByIdQuery(baseOptions: Apollo.QueryHookOptions<GetPatientByIdQuery, GetPatientByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPatientByIdQuery, GetPatientByIdQueryVariables>(GetPatientByIdDocument, options);
      }
export function useGetPatientByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPatientByIdQuery, GetPatientByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPatientByIdQuery, GetPatientByIdQueryVariables>(GetPatientByIdDocument, options);
        }
export type GetPatientByIdQueryHookResult = ReturnType<typeof useGetPatientByIdQuery>;
export type GetPatientByIdLazyQueryHookResult = ReturnType<typeof useGetPatientByIdLazyQuery>;
export type GetPatientByIdQueryResult = Apollo.QueryResult<GetPatientByIdQuery, GetPatientByIdQueryVariables>;
export const GetWorkoutProgramByIdDocument = gql`
    query GetWorkoutProgramById($id: ID!) {
  getWorkoutProgramById(id: $id) {
    id
    weeks
    isCompleted
    stats {
      weeksCompleted
      weeksRemaining
      workoutsCompleted
      workoutsRemaining
    }
    days {
      id
      name
      key
      weekNumber
      dayNumber
      isLocked
      isCompleted
      isStarted
      tasks {
        id
        key
        isCompleted
        isStarted
        taskable {
          ... on Workout {
            ...WorkoutFragment
          }
          ... on Assessment {
            ...AssessmentFragment
          }
          ... on Activity {
            ...ActivityFragment
          }
          ... on WellTip {
            ...WellTipFragment
          }
        }
      }
    }
  }
}
    ${WorkoutFragmentFragmentDoc}
${AssessmentFragmentFragmentDoc}
${ActivityFragmentFragmentDoc}
${WellTipFragmentFragmentDoc}`;

/**
 * __useGetWorkoutProgramByIdQuery__
 *
 * To run a query within a React component, call `useGetWorkoutProgramByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkoutProgramByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkoutProgramByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetWorkoutProgramByIdQuery(baseOptions: Apollo.QueryHookOptions<GetWorkoutProgramByIdQuery, GetWorkoutProgramByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWorkoutProgramByIdQuery, GetWorkoutProgramByIdQueryVariables>(GetWorkoutProgramByIdDocument, options);
      }
export function useGetWorkoutProgramByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWorkoutProgramByIdQuery, GetWorkoutProgramByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWorkoutProgramByIdQuery, GetWorkoutProgramByIdQueryVariables>(GetWorkoutProgramByIdDocument, options);
        }
export type GetWorkoutProgramByIdQueryHookResult = ReturnType<typeof useGetWorkoutProgramByIdQuery>;
export type GetWorkoutProgramByIdLazyQueryHookResult = ReturnType<typeof useGetWorkoutProgramByIdLazyQuery>;
export type GetWorkoutProgramByIdQueryResult = Apollo.QueryResult<GetWorkoutProgramByIdQuery, GetWorkoutProgramByIdQueryVariables>;
export const GetWorkoutImportStatusDocument = gql`
    query GetWorkoutImportStatus($slug: String!) {
  getWorkoutImportStatus(slug: $slug) {
    status
  }
}
    `;

/**
 * __useGetWorkoutImportStatusQuery__
 *
 * To run a query within a React component, call `useGetWorkoutImportStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkoutImportStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkoutImportStatusQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGetWorkoutImportStatusQuery(baseOptions: Apollo.QueryHookOptions<GetWorkoutImportStatusQuery, GetWorkoutImportStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWorkoutImportStatusQuery, GetWorkoutImportStatusQueryVariables>(GetWorkoutImportStatusDocument, options);
      }
export function useGetWorkoutImportStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWorkoutImportStatusQuery, GetWorkoutImportStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWorkoutImportStatusQuery, GetWorkoutImportStatusQueryVariables>(GetWorkoutImportStatusDocument, options);
        }
export type GetWorkoutImportStatusQueryHookResult = ReturnType<typeof useGetWorkoutImportStatusQuery>;
export type GetWorkoutImportStatusLazyQueryHookResult = ReturnType<typeof useGetWorkoutImportStatusLazyQuery>;
export type GetWorkoutImportStatusQueryResult = Apollo.QueryResult<GetWorkoutImportStatusQuery, GetWorkoutImportStatusQueryVariables>;
export const GetWorkoutsImportStatusDocument = gql`
    query GetWorkoutsImportStatus {
  getWorkoutsImportStatus {
    status
  }
}
    `;

/**
 * __useGetWorkoutsImportStatusQuery__
 *
 * To run a query within a React component, call `useGetWorkoutsImportStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkoutsImportStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkoutsImportStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetWorkoutsImportStatusQuery(baseOptions?: Apollo.QueryHookOptions<GetWorkoutsImportStatusQuery, GetWorkoutsImportStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWorkoutsImportStatusQuery, GetWorkoutsImportStatusQueryVariables>(GetWorkoutsImportStatusDocument, options);
      }
export function useGetWorkoutsImportStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWorkoutsImportStatusQuery, GetWorkoutsImportStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWorkoutsImportStatusQuery, GetWorkoutsImportStatusQueryVariables>(GetWorkoutsImportStatusDocument, options);
        }
export type GetWorkoutsImportStatusQueryHookResult = ReturnType<typeof useGetWorkoutsImportStatusQuery>;
export type GetWorkoutsImportStatusLazyQueryHookResult = ReturnType<typeof useGetWorkoutsImportStatusLazyQuery>;
export type GetWorkoutsImportStatusQueryResult = Apollo.QueryResult<GetWorkoutsImportStatusQuery, GetWorkoutsImportStatusQueryVariables>;
export const GetExerciseImportStatusDocument = gql`
    query GetExerciseImportStatus($slug: String!) {
  getExerciseImportStatus(slug: $slug) {
    status
  }
}
    `;

/**
 * __useGetExerciseImportStatusQuery__
 *
 * To run a query within a React component, call `useGetExerciseImportStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExerciseImportStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExerciseImportStatusQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGetExerciseImportStatusQuery(baseOptions: Apollo.QueryHookOptions<GetExerciseImportStatusQuery, GetExerciseImportStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetExerciseImportStatusQuery, GetExerciseImportStatusQueryVariables>(GetExerciseImportStatusDocument, options);
      }
export function useGetExerciseImportStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetExerciseImportStatusQuery, GetExerciseImportStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetExerciseImportStatusQuery, GetExerciseImportStatusQueryVariables>(GetExerciseImportStatusDocument, options);
        }
export type GetExerciseImportStatusQueryHookResult = ReturnType<typeof useGetExerciseImportStatusQuery>;
export type GetExerciseImportStatusLazyQueryHookResult = ReturnType<typeof useGetExerciseImportStatusLazyQuery>;
export type GetExerciseImportStatusQueryResult = Apollo.QueryResult<GetExerciseImportStatusQuery, GetExerciseImportStatusQueryVariables>;
export const GetExercisesImportStatusDocument = gql`
    query GetExercisesImportStatus {
  getExercisesImportStatus {
    status
  }
}
    `;

/**
 * __useGetExercisesImportStatusQuery__
 *
 * To run a query within a React component, call `useGetExercisesImportStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExercisesImportStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExercisesImportStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetExercisesImportStatusQuery(baseOptions?: Apollo.QueryHookOptions<GetExercisesImportStatusQuery, GetExercisesImportStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetExercisesImportStatusQuery, GetExercisesImportStatusQueryVariables>(GetExercisesImportStatusDocument, options);
      }
export function useGetExercisesImportStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetExercisesImportStatusQuery, GetExercisesImportStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetExercisesImportStatusQuery, GetExercisesImportStatusQueryVariables>(GetExercisesImportStatusDocument, options);
        }
export type GetExercisesImportStatusQueryHookResult = ReturnType<typeof useGetExercisesImportStatusQuery>;
export type GetExercisesImportStatusLazyQueryHookResult = ReturnType<typeof useGetExercisesImportStatusLazyQuery>;
export type GetExercisesImportStatusQueryResult = Apollo.QueryResult<GetExercisesImportStatusQuery, GetExercisesImportStatusQueryVariables>;
export const GetRecommendedWorkoutPersonaFromFitnessProfileDocument = gql`
    query GetRecommendedWorkoutPersonaFromFitnessProfile($fitness: [ProfileItemAttributes!]!) {
  getRecommendedWorkoutPersonaFromFitnessProfile(fitness: $fitness) {
    workoutPersona {
      ...WorkoutPersonaFragment
    }
    workoutType
  }
}
    ${WorkoutPersonaFragmentFragmentDoc}`;

/**
 * __useGetRecommendedWorkoutPersonaFromFitnessProfileQuery__
 *
 * To run a query within a React component, call `useGetRecommendedWorkoutPersonaFromFitnessProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecommendedWorkoutPersonaFromFitnessProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecommendedWorkoutPersonaFromFitnessProfileQuery({
 *   variables: {
 *      fitness: // value for 'fitness'
 *   },
 * });
 */
export function useGetRecommendedWorkoutPersonaFromFitnessProfileQuery(baseOptions: Apollo.QueryHookOptions<GetRecommendedWorkoutPersonaFromFitnessProfileQuery, GetRecommendedWorkoutPersonaFromFitnessProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRecommendedWorkoutPersonaFromFitnessProfileQuery, GetRecommendedWorkoutPersonaFromFitnessProfileQueryVariables>(GetRecommendedWorkoutPersonaFromFitnessProfileDocument, options);
      }
export function useGetRecommendedWorkoutPersonaFromFitnessProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRecommendedWorkoutPersonaFromFitnessProfileQuery, GetRecommendedWorkoutPersonaFromFitnessProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRecommendedWorkoutPersonaFromFitnessProfileQuery, GetRecommendedWorkoutPersonaFromFitnessProfileQueryVariables>(GetRecommendedWorkoutPersonaFromFitnessProfileDocument, options);
        }
export type GetRecommendedWorkoutPersonaFromFitnessProfileQueryHookResult = ReturnType<typeof useGetRecommendedWorkoutPersonaFromFitnessProfileQuery>;
export type GetRecommendedWorkoutPersonaFromFitnessProfileLazyQueryHookResult = ReturnType<typeof useGetRecommendedWorkoutPersonaFromFitnessProfileLazyQuery>;
export type GetRecommendedWorkoutPersonaFromFitnessProfileQueryResult = Apollo.QueryResult<GetRecommendedWorkoutPersonaFromFitnessProfileQuery, GetRecommendedWorkoutPersonaFromFitnessProfileQueryVariables>;
export const GetUserNotificationsByUserIdDocument = gql`
    query GetUserNotificationsByUserId($userId: ID!) {
  getUserNotificationsByUserId(userId: $userId) {
    id
    notificationType
    notificationMethod
    isSubscribed
    isUnsubscribed
    metadata {
      notificationDays
      notificationHour
      notificationMinute
      notificationTime
      notificationTimezone
    }
  }
}
    `;

/**
 * __useGetUserNotificationsByUserIdQuery__
 *
 * To run a query within a React component, call `useGetUserNotificationsByUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserNotificationsByUserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserNotificationsByUserIdQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserNotificationsByUserIdQuery(baseOptions: Apollo.QueryHookOptions<GetUserNotificationsByUserIdQuery, GetUserNotificationsByUserIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserNotificationsByUserIdQuery, GetUserNotificationsByUserIdQueryVariables>(GetUserNotificationsByUserIdDocument, options);
      }
export function useGetUserNotificationsByUserIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserNotificationsByUserIdQuery, GetUserNotificationsByUserIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserNotificationsByUserIdQuery, GetUserNotificationsByUserIdQueryVariables>(GetUserNotificationsByUserIdDocument, options);
        }
export type GetUserNotificationsByUserIdQueryHookResult = ReturnType<typeof useGetUserNotificationsByUserIdQuery>;
export type GetUserNotificationsByUserIdLazyQueryHookResult = ReturnType<typeof useGetUserNotificationsByUserIdLazyQuery>;
export type GetUserNotificationsByUserIdQueryResult = Apollo.QueryResult<GetUserNotificationsByUserIdQuery, GetUserNotificationsByUserIdQueryVariables>;
export const GetUserSubscriptionByUserIdDocument = gql`
    query GetUserSubscriptionByUserId($userId: ID!) {
  getUserSubscriptionByUserId(userId: $userId) {
    id
    planType
    interval
    state
    trialEndsAt
    trialDaysRemaining
    stripeSubscriptionId
    cancelAt
    hasPayment
    currencyCode
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetUserSubscriptionByUserIdQuery__
 *
 * To run a query within a React component, call `useGetUserSubscriptionByUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserSubscriptionByUserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserSubscriptionByUserIdQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserSubscriptionByUserIdQuery(baseOptions: Apollo.QueryHookOptions<GetUserSubscriptionByUserIdQuery, GetUserSubscriptionByUserIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserSubscriptionByUserIdQuery, GetUserSubscriptionByUserIdQueryVariables>(GetUserSubscriptionByUserIdDocument, options);
      }
export function useGetUserSubscriptionByUserIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserSubscriptionByUserIdQuery, GetUserSubscriptionByUserIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserSubscriptionByUserIdQuery, GetUserSubscriptionByUserIdQueryVariables>(GetUserSubscriptionByUserIdDocument, options);
        }
export type GetUserSubscriptionByUserIdQueryHookResult = ReturnType<typeof useGetUserSubscriptionByUserIdQuery>;
export type GetUserSubscriptionByUserIdLazyQueryHookResult = ReturnType<typeof useGetUserSubscriptionByUserIdLazyQuery>;
export type GetUserSubscriptionByUserIdQueryResult = Apollo.QueryResult<GetUserSubscriptionByUserIdQuery, GetUserSubscriptionByUserIdQueryVariables>;