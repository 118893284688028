import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/20/solid";

type TableHeaderCol = {
  children?: React.ReactNode;
  sort?: "asc" | "desc";
  onClick?: () => void;
};

export default function TableHeaderCol({
  onClick = undefined,
  sort = undefined,
  children = null
}: TableHeaderCol) {
  return (
    <th
      className="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-cello-500 sm:pl-0"
      scope="col"
    >
      {sort ? (
        <button className="mt-1 group inline-flex uppercase" onClick={onClick}>
          {children}
          <span className="ml-2 flex-none rounded bg-gray group-hover:bg-gray-200">
            {sort == "asc" ? (
              <ChevronUpIcon className="h-5 w-5" />
            ) : (
              <ChevronDownIcon aria-hidden="true" className="h-5 w-5" />
            )}
          </span>
        </button>
      ) : (
        children
      )}
    </th>
  );
}
